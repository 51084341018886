import React, { useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FAB from '../../components/FAB.jsx';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { WayaPayLogo1, Icon, FailedIcon, CloseIcon, Loader } from '../../assets/index.js';
import { Modal } from '@mui/material'
import axios from 'axios';
import config from '../../config.js';
import OtpInput from '../../components/OtpInput.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AuthContext} from "../../context/AuthContext";
import { authOTP } from "../../services/requests/auth";
import newLogo  from '../../assets/newLogo.png'
import { useEffect } from 'react';

function Login() {
    const loginTab = useRef(null);
    const otpTab = useRef(null);
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(1);
    const [loading, setLoading] = useState(false);
    const [otpTabTitle, setOTPTabTitle] = useState("You are yet to validate your account. Enter the OTP sent to your phone number or email to continue");

    const [email, setEmail] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [password, setPassword] = useState(null);
    const [otp1, setOTP1] = useState(null);
    const [otp2, setOTP2] = useState(null);
    const [otp3, setOTP3] = useState(null);
    const [otp4, setOTP4] = useState(null);
    const [otp5, setOTP5] = useState(null);
    const [otp6, setOTP6] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const [status, setStatus] = useContext(AuthContext);
    const [otpModal, setOtpModal] = useState(false);
    const [otp, setOTP] = useState('');
    const [isShowLoader, setIsShowLoader] = useState(true);

    const SetEmail = (e) => {
        setEmail(e.target.value)
    }
    const SetPassword = (e) => {
        setPassword(e.target.value)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        boxShadow: 24,
        p: 4,
      };

    const ResendOTP = () => {
        setLoading(true);
        const API_ENDPOINT = `${config.BASE_URL}/auth-service/api/v1/auth/resend-otp/signup/${email}`;
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }
        axios.get(API_ENDPOINT, configuration).then(response => {
            setOTPTabTitle("OTP sent successfully. Enter the OTP sent to your phone number or email to continue")
            setErrMsg(null);
            toast("OTP sent successfully.");
            setLoading(false);
        }).catch(err => {
            if (Array.isArray(err?.response?.data?.data)) {
                setErrMsg(err?.response?.data?.data.join('. '));
                setLoading(false);
                toast("Error occured: "+ err?.response?.data?.data)
            } 
            if(err.toJSON().message === 'Network Error'){
                toast("Check your network connection");
                setLoading(false);
                setErrMsg("Check your network and retry.");
            }
            if(JSON.stringify(err.toJSON().message).search("timeout") === 1){
                setErrMsg("Service timeout. Please retry");
                toast("Error: Service timeout. Please retry");
                setLoading(false)
            }else{
                setErrMsg(err.response.data.message);
                toast(err.response.data.message);
                setLoading(false);
            }
        });
    }

    const otpHandler = () => {
        setLoading(true);
        const otp = (otp1 + otp2 + otp3 + otp4 + otp5 + otp6);
        const otpValidateData = {
            otp: JSON.parse(otp),
            phoneOrEmail: email
        }
        
        const API_ENDPOINT = `${config.BASE_URL}/auth-service/api/v1/auth/verify-otp`;
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }
        axios.post(API_ENDPOINT, otpValidateData, configuration).then(response => {
            setLoading(false);
            toast("OTP verified");
            setErrMsg(null);
            setTimeout(() => {
                window.location.reload();    
            }, 3000);
        }).catch(err => {
            if (err?.response?.data?.data.join('. ')) {
                if (err?.response?.data?.data.join('. ').includes("not Verified")) {
                    toast("Account not verified!");
                    loginTab?.current?.classList.add("hidden");
                    otpTab?.current?.classList.remove("hidden");
                }
            }
            setErrMsg(err?.response?.data?.data?.join('. '));
            setLoading(false);
            if(err.toJSON().message === 'Network Error'){
                toast("Check your network connection");
                setLoading(false);
                setErrMsg("Check your network and retry.");
            }
            if(JSON.stringify(err.toJSON().message).search("timeout") === 1){
                setErrMsg("Service timeout. Please retry");
                toast("Error: Service timeout. Please retry");
                setLoading(false);
            }else{
                setErrMsg(err.response.data.message);
                toast(err.response.data.message);
                setLoading(false);
            }
        });
    }

    const loginHandler = () => {
        setLoading(true);
        const loginPayload = {
            emailOrPhoneNumber: email?.toLowerCase(),
            password: password,
            otp: otp
        }
        const API_ENDPOINT = `${config.BASE_URL_AUTH}/auth-service/api/v1/auth/login`;
        const configuration = {
            timeout: config.REQUEST_TIMEOUT
        }
        if (email && password) {
            axios.post(API_ENDPOINT, loginPayload, configuration).then(response => {
                //  if(response?.data?.data?.passwordAge <= config.PASSWORD_EXPIRY_DATE){
                    if (response?.data?.data?.corporate === true) {
                        let session = sessionStorage;
                        if(response?.data?.data?.merchantId){
                            if(response?.data?.data?.token){
                                    setLoading(false);
                                    sessionStorage.setItem("isLoggedin", true);
                                    session.setItem("token", JSON.stringify(response?.data?.data?.token));
                                    session.setItem("userData", JSON.stringify(response?.data?.data?.user));
                                   // localStorage.setItem("userId", JSON.stringify(response?.data?.data?.userId));
                                    session.setItem("merchantId", JSON.stringify(response?.data?.data?.merchantId));
                                    session.setItem("isCorporate", JSON.stringify(response?.data?.data?.corporate));
                                    setErrMsg(null);
                                    toast("Login successfully.");
                                    setStatus(true);
                                    setTimeout(() => {
                                        navigate("/");
                                        if(response?.data?.pinCreated){
                                            toast("Set your pin to continue");
                                        }
                                        // window.location.href = `http://127.0.0.1:3000`
                                        // window.location.href = `${config.WEBSITE_URL}`
                                    }, 3000);
                            }else{
                                toast("user Token not defined");
                                setLoading(false);
                            }
                        }else{
                            const configuration = {
                                timeout: config.REQUEST_TIMEOUT,
                                headers: {
                                    'content-type': 'application/json',
                                    authorization: response ?.data ?.data ?.token,
                                    'CLIENT-ID': 'WAYAQUICK',
                                    'CLIENT-TYPE': 'CORPORATE',
                                },
                            }
                            const userId = response?.data?.data?.user?.id;
                            
                            axios.post(`${config.BASE_URL_AUTH}/identity-manager-service/api/v1/waya-merchant/init/${userId}`, configuration).then(resp2=> {
                                if(resp2?.data?.data?.merchantId){
                                    setLoading(false);
                                    sessionStorage.setItem("isLoggedin", true);
                                    session.setItem("token", JSON.stringify(response?.data?.data?.token));
                                    session.setItem("userData", JSON.stringify(response?.data?.data?.user));
                                    session.setItem("merchantId", JSON.stringify(resp2?.data?.data?.merchantId));
                                    session.setItem("isCorporate", JSON.stringify(response?.data?.data?.corporate));
                                    setErrMsg(null);
                                    toast("Login successfully.");
                                    setStatus(true);
                                    setTimeout(() => {
                                        navigate("/");
                                        if(response?.data?.pinCreated){
                                            toast("Set your pin to continue");
                                        }
                                        // window.location.href = `http://127.0.0.1:3000`
                                        // window.location.href = `${config.WEBSITE_URL}`
                                    }, 3000);
                                }else{
                                    toast("Merchant ID is empty");
                                    setLoading(false);
                                }
                            });
                        }
                    }else{
                        setErrMsg("Account is not corporate.");
                        toast("Account is not corporate");
                        setLoading(false);
                    }
                /*  }else{
                    setTabIndex(3);
                    setLoading(false);
                } */
            }).catch(err => {
                if (err?.response?.data?.message?.includes("not Verified")) {
                    toast("Account not verified!");
                    setTabIndex(2);
                    setLoading(false)
                }
                if (Array.isArray(err?.response?.data?.data)) {
                    setErrMsg(err?.response?.data?.data.join('. '));
                    toast("Error occured")
                    setLoading(false)
                }
                if(err.toJSON().message === 'Network Error'){
                    toast("Check your network connection");
                    setLoading(false);
                    setErrMsg("Check your network and retry.");
                }
                if(JSON.stringify(err.toJSON().message).search("timeout") === 1){
                    setErrMsg("Service timeout. Please retry");
                    toast("Error: Service timeout. Please retry");
                    setLoading(false)
                }else{
                    setErrMsg(err?.response?.data?.message);
                    toast(err?.response?.data?.message || 'An error occurred');
                    setLoading(false);
                }
            });
        } else {
            toast("All fields required!");
            setErrMsg("Kindly fill all fields.");
            setLoading(false);
        }
    }

    useEffect(() => {
        //  window.location.href='https://staging.wayabank.ng/login?client=wayaquick'
        window.location.href=`${config?.BANK_WEB_URL}/login?client=wayaquick`
        // window.location.href='http://localhost:3001/login?client=wayaquick'
    }, []);
    const generateOTP = async () => {
        try {
            setLoading(true);
            const res = await authOTP(email)
            if(!res.status && res.message.includes('2FA not active for this user')) {
                return loginHandler()
            }
            toast.success('Check your email to verify OTP');
            setOtpModal(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err.message || 'An error occured!');
        }
    }

    return (
        <>
        {isShowLoader ? <Loader /> : (
        <React.Fragment>
        <ToastContainer />
            <div className="onboarding-background w-full h-auto flex items-start justify-center">
                <div className="onboarding-form-container bg-white border-t-4 border-primary-theme pb-12">
                    <div className="flex flex-col items-center pt-10 px-6">
                        <Link to="/">
                            <img src={newLogo} width='150px'/>
                        </Link>

                        <h4 className="title font-semibold text-xl text-gray-500 mt-10 mb-6">Welcome Back</h4>
                    </div>
                    {
                        tabIndex === 1 && (
                            <div className="input-wrapper px-10">
                                <Input label="Email or Phone number" name="email" placeholder="Enter Email or Phone number" value={email} onChange={SetEmail} />
                                
                                <Input name="password" label="Password" placeholder="Enter Password" type="password" value={password} onChange={SetPassword} icon={<Icon />} />

                                <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
                                ><span>
                                  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path>
                                  </svg>
                                </span>
                                <span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>

                                <Button text="Log In" actionHandler={generateOTP} loading={loading} />

                                <p className="text-sm text-center text-gray-500">Don't have an account <Link to="/register" className="text-primary-theme">Sign up instead</Link></p>

                                <p className="text-sm text-center text-primary-theme mt-4"><Link to="/forget-password">Forgot your password?</Link></p>
                            </div>
                        )
                    }

                    {
                        tabIndex === 2 && (
                            <div className="input-wrapper px-10">

                                <h5 className="text-center text-md text-gray-500 mt-3 mb-8">{otpTabTitle}</h5>
                                <OtpInput otp1={otp1} otp2={otp2} otp3={otp3} otp4={otp4} otp5={otp5} otp6={otp6} setOTP1={setOTP1} setOTP2={setOTP2} setOTP3={setOTP3} setOTP4={setOTP4} setOTP5={setOTP5} setOTP6={setOTP6} />

                                <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
                                ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>

                                <Button text="Log In" actionHandler={otpHandler} />
                                <p className="text-sm text-center text-primary-theme cursor-pointer" onClick={ResendOTP}>Resend OTP</p>

                                <p className="text-sm text-center text-gray-500 mt-4"><Link to="/forget-password">Forgot your password?</Link></p>
                            </div>
                        )
                    }

                    {
                        tabIndex === 3 && (
                            <div className="overlay w-full h-screen flex justify-center fixed top-0 left-0 z-20">
                                <div className="w-full md:w-1/2 lg:w-2/5 px-5 py-12 bg-white flex flex-col items-center h-80 rounded mt-32">
                                    <FailedIcon />
                                    <h5 className="text-xl text-center my-5">Sorry your password has expired,<br/> kindly click button below to reset your password. </h5>
                                    {/* <br/> */}
                                    <Link to="/change-password" className="flex text-lg font-medium justify-center text-primary-theme" onClick={()=> setTabIndex(1)}>
                                        Reset password
                                    </Link>
                                </div>
                            </div>
                        )
                    }

                </div>

                <FAB />
            </div>
        </React.Fragment>
        )}

        <Modal 
         open={otpModal} 
         onClose={() => setOtpModal(false)}
         aria-labelledby="verify otp modal"
         aria-describedby="input otp from email"
        >
        <div className="input-wrapper px-10 max-w-2xl bg-white" style={style}>
        
            <h2 className="font-bold text-2xl">Enter OTP</h2>
            <Input label="OTP" name="otp" placeholder="Enter OTP" value={otp} onChange={(e)=>setOTP(e.target.value)} notRequired={false} />
            
            <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
            ><span><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg></span><span className="text-primary-theme ml-2 text-xs">{errMsg}</span></p>

            <Button text="Log In" actionHandler={loginHandler} loading={loading} />
        </div>
       </Modal>
     </>
    )
}

export default Login
