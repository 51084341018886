import React, { useRef, useState, useEffect } from 'react'
import { Modal } from "@mui/material";
import Select from 'react-select'
import { KYCNavigation1Icon, CloseIcon, AndroidIcon, IOSIcon, KYCNavigation2Icon, KYCNavigation3Icon, KYCNavigation4Icon, AttachIcon, LoaderIcon, SuccessIcon } from '../assets';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import config from '../config.js';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {getBankLists, getAccountName} from '../services/requests/banks';
import {Link} from 'react-router-dom';
import { Flex, Progress } from 'antd';
import { ArrowForward, Input } from '@material-ui/icons';
import PinModal from './modals/pinModal.jsx';
import Button from './Button.jsx';
import { updateProfile } from '../services/requests/profile.js';

const registrationTypes = [
    {label: "Incorporated Company", value: "INCORPORATED_COMPANY"},
    {label: "Incorporated Trustees", value: "INCORPORATED_TRUSTEES"},
    {label: "Business name registration", value: "BUSINESS_NAME_REGISTRATION"}
]

function KYC() {
    const uploadLogo = useRef();
    const identificationTypeRef = useRef();
    const businessAddressProveTypeRef = useRef();
    const businessCertificateTypeRef = useRef();
    const businessRegistrationTypeRef = useRef();
    const uploadCertOfIncRef = useRef();
    const uploadCertOfBusNameRef = useRef();
    const uploadCacLtRef = useRef();
    const uploadBNCacRef = useRef();
    const memartRef = useRef();
    const dirPicRef = useRef();
    const dirNinRef = useRef();
    // const cert_of_cac = useRef();
    // const cert_of_inc = useRef();
    const [errorData, setErrorData] = useState([])
    const [tab, setTab] = useState(0);
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('');
    const [loadState, setLoadState] = useState(false)
    const [businessLogo, setBusinessLogo] = useState(null);
    const [industryListObj, setIndustryListObj] = useState(null);
    const [industryList, setIndustryList] = useState(null);
    const [supportedBank, setSupportedBank] = useState([]);
    const [unfilteredBanks, setUnfilteredBanks] = useState("");
    const [loading, setLoading] = useState(false);
    const [showpin, setShowpin] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [otp, setOtp] = useState('');
    const [userData, setUserData] = useState(null);
    const [directorInfo, setDirectoInfo] = useState([]);
    
    const [KYCInfo, setKYCInfo] = useState({
        // businessName: sessionStorage.getItem("businessName"),
        // businessDescription: "",
        // businessType: "REGISTERED_BUSINESS",
        // **************
        "appLink": "",
        "businessAccountName": "",
        "businessAccountNumber": "",
        "businessAccountProvider": "",
        "businessAddressProveType": "UTILITY_BILL",
        "businessAddressProveUrl": "",
        "businessCertificateType": "CERTIFICATE_OF_INCORPORATION",
        "businessCertificateUrl": "",
        "businessDescription": "",
        "businessEmail": "",
        "businessIndustry": 1,
        "businessLogoUrl": "",
        "businessName": sessionStorage.getItem("businessName"),
        "businessNationality": "",
        "businessPhoneNumber": "",
        "businessRegistrationNumber": "",
        "businessRegistrationType": "INCORPORATED_COMPANY",
        "businessType": "REGISTERED_BUSINESS",
        "bvn": "",
        "city": "",
        "country": "",
        "draft": true,
        "facebookUrl": "",
        "identificationType": "NATIONAL_ID",
        "identificationUrl": "",
        "instagramUrl": "",
        "lga": "",
        "merchantId": JSON.parse(sessionStorage.getItem("merchantId")),
        "state": "",
        "streetAddress": "",
        "tin": "",
        "twitterUrl": "",
        "usePaymentLink": false,
        "websiteLink": ""
        // **************
        // businessIndustry: JSON.parse(sessionStorage.getItem("otherInfo"))?.otherDetails?.businessType
    });

    const getKycData = async () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                // 'Content-Type': 'application/json',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        // Fetch KYC info
        const userId = JSON.parse(sessionStorage.getItem("userData")).id;
        axios.post(`${config.BASE_URL}/kyc-servicev2/api/v1/kyc/requirementData/${userId}`, {}, header).then(resp => {
            console.log({ resp });
            const email1 = resp?.data?.data?.tier1?.filter(
                (e) => e?.requirementType === 'EMAIL'
              )[0];
              const phone1 = resp?.data?.data?.tier1?.filter(
                (e) => e?.requirementType === 'PHONE_NUMBER'
              )[0];
            setEmail(email1);
            setPhone(phone1);
            
        }).catch(err => {
            // if(err.response.status===400){
                // setKYCInfo({
                //     businessName: sessionStorage.getItem("businessName"),
                //     businessDescription: "a",
                //     // businessIndustry: JSON.parse(sessionStorage.getItem("otherInfo"))?.otherDetails?.businessType
                // })
            // }
        });

    }
    const handleUpdateEmail = async () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                // 'Content-Type': 'application/json',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        // Fetch KYC info
        const _email = JSON.parse(sessionStorage.getItem("userData")).email;
        const _phoneNumber = JSON.parse(sessionStorage.getItem("userData")).phoneNumber;
        const businesId = JSON.parse(sessionStorage.getItem("otherInfo")).loginId;
        axios.get(`${config.BASE_URL}/auth-service/api/v1/auth/resend-otp-mail/${_email}?businessId=${businesId}`, header).then(resp => {
            if (resp.data.status) {
                setShowpin(true);
            }            
        }).catch(err => {
            // if(err.response.status===400){
                // setKYCInfo({
                //     businessName: sessionStorage.getItem("businessName"),
                //     businessDescription: "a",
                //     // businessIndustry: JSON.parse(sessionStorage.getItem("otherInfo"))?.otherDetails?.businessType
                // })
            // }
        });

    }
    const handleUpdateEmailKyc = async () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                // 'Content-Type': 'application/json',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        // Fetch KYC info
        axios.put(`${config.BASE_URL}/kyc-servicev2/api/v1/kyc/updateCustomerTierData/${email.id}/${email.customerId}?attachment=${email.verificationReference}&reference=${email.verificationReference}`,
        {},
        header).then(resp => {
        getKycData();      
        }).catch(err => {
            // if(err.response.status===400){
                // setKYCInfo({
                //     businessName: sessionStorage.getItem("businessName"),
                //     businessDescription: "a",
                //     // businessIndustry: JSON.parse(sessionStorage.getItem("otherInfo"))?.otherDetails?.businessType
                // })
            // }
        });

    }
    const handleUpdateEmailOtp = async () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                // 'Content-Type': 'application/json',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        // Fetch KYC info
        const _email = JSON.parse(sessionStorage.getItem("userData")).email;
        const _phoneNumber = JSON.parse(sessionStorage.getItem("userData")).phoneNumber;
        const businessId = JSON.parse(sessionStorage.getItem("otherInfo")).loginId;
        axios.post(`${config.BASE_URL}/auth-service/api/v1/auth/verify-email`,
        {
          phoneOrEmail: _email,
          otp,
          businessId,
        }, header).then(resp => {
            if (resp.data.status) {
                setShowpin(false);
                handleUpdateEmailKyc();
                setOtp('');
            }
            toast.success(resp.message);
           
        }).catch(err => {
            toast.error(err.response.data.message);            
            // if(err.response.status===400){
                // setKYCInfo({
                //     businessName: sessionStorage.getItem("businessName"),
                //     businessDescription: "a",
                //     // businessIndustry: JSON.parse(sessionStorage.getItem("otherInfo"))?.otherDetails?.businessType
                // })
            // }
        });

    }

    const handleUpdatePhone = async () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                // 'Content-Type': 'application/json',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        // Fetch KYC info
        //  const _email = JSON.parse(sessionStorage.getItem("userData")).email;
        const _phoneNumber = JSON.parse(sessionStorage.getItem("userData")).phoneNumber;
        const businesId = JSON.parse(sessionStorage.getItem("otherInfo")).loginId;
        axios.get(`${config.BASE_URL}/auth-service/api/v1/auth/resend-otp/${_phoneNumber}?businessId=${businesId}`, header).then(resp => {
            if (resp.data.status) {
                setShowpin(true);
            }            
        }).catch(err => {
          
        });

    }
    const handleUpdatePhoneKyc = async () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                // 'Content-Type': 'application/json',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        // Fetch KYC info
        axios.put(`${config.BASE_URL}/kyc-servicev2/api/v1/kyc/updateCustomerTierData/${phone.id}/${phone.customerId}?attachment=${phone.verificationReference}&reference=${email.verificationReference}`,
        {},
        header).then(resp => {
                getKycData();       
        }).catch(err => {
            // if(err.response.status===400){
                // setKYCInfo({
                //     businessName: sessionStorage.getItem("businessName"),
                //     businessDescription: "a",
                //     // businessIndustry: JSON.parse(sessionStorage.getItem("otherInfo"))?.otherDetails?.businessType
                // })
            // }
        });

    }
    const handleUpdatePhoneOtp = async () => {
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                // 'Content-Type': 'application/json',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        // Fetch KYC info
        const _email = JSON.parse(sessionStorage.getItem("userData")).email;
        const _phoneNumber = JSON.parse(sessionStorage.getItem("userData")).phoneNumber;
        const businessId = JSON.parse(sessionStorage.getItem("otherInfo")).loginId;
        axios.post(`${config.BASE_URL}/auth-service/api/v1/auth/verify-phone`,
        {
          phoneOrEmail: _phoneNumber,
          otp,
          businessId,
        }, header).then(resp => {
            console.log({ rfff: resp.data})
            if (resp.data.status) {
                setShowpin(false);
                handleUpdatePhoneKyc();
                setOtp('');
            }
            toast.success(resp.data?.message);            
        }).catch(err => {
            toast.error(err.response.data.message);            
            // if(err.response.status===400){
                // setKYCInfo({
                //     businessName: sessionStorage.getItem("businessName"),
                //     businessDescription: "a",
                //     // businessIndustry: JSON.parse(sessionStorage.getItem("otherInfo"))?.otherDetails?.businessType
                // })
            // }
        });

    }

    useEffect(async ()=>{
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const userInfo = JSON.parse(sessionStorage.getItem("userData"));
        setUserData(userInfo);
        const header = {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        
      await axios.get(`${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc/business-industry`, header).then(resp => {
            // toast.success("New credentials generated.");
            let lists=[];
           setIndustryListObj(resp.data.data);
            resp.data.data.forEach((elem, index)=>{
                lists.push({
                    label: elem.name,
                    value: index+1
                })
            });
            setIndustryList(lists);
        }).catch(err => {
            console.log(err);
        });
        
        // Fetch bank lists
        getBankLists().then(response=>{
            // toast("Success");
            const bankLists = [];
            setUnfilteredBanks(response.data);

            response.data.forEach(bank=>{
                bankLists.push({
                    label: bank.bankName,
                    value: bank.bankName //bankCode
                })
            })
            setSupportedBank(bankLists);
        }).catch(err=>{
            console.log(err.response);
            toast("Error occured...")
        });

        // Fetch KYC info
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
        await axios.get(`${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc/${merchantId}`, header).then(async resp => {
            toast.success("New credentials generated.");
            const kycData = resp.data.data;
            const businessId = kycData.businessIndustry.id;
            setKYCInfo({
                ...kycData,
                businessIndustry: businessId
            })
            setBusinessLogo(kycData?.businessLogoUrl)
        }).catch(err => {
            // if(err.response.status===400){
                // setKYCInfo({
                //     businessName: sessionStorage.getItem("businessName"),
                //     businessDescription: "a",
                //     // businessIndustry: JSON.parse(sessionStorage.getItem("otherInfo"))?.otherDetails?.businessType
                // })
            // }
        });

        
    },[]);
    useEffect(async()=>{
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));

         await axios.get(`${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc/fetchDirectorKyc?merchantId=${merchantId}`, header).then(resp => {
                // toast.success("New credentials generated.");
                const { data } = resp.data
                console.log({ data });
                setDirectoInfo({
                    ...directorInfo,
                    directorFirstName: data?.firstName,
                    directorLastName: data?.lastName,
                    directorBvnNumber: data?.bvnNumber,
                    directorNinNumber: data?.ninNumber,
                    directorNinImageUrl: data?.directorNinImageUrl,
                    directorPhotoUrl: data?.directorPhotoUrl,    
                })
                // setDirectoInfo(resp.data.data);
            }).catch(err => {
                console.log(err);
            });
    },[]);
    useEffect(()=>{
        getKycData();
    },[]);
    const businessTypes = [
        { value: "REGISTERED_BUSINESS", label: "REGISTERED BUSINESS" },
        { value: "NON_REGISTERED_BUSINESS", label: "NON REGISTERED BUSINESS" }
    ]

    const uploadImage = () => {
        uploadLogo.current.click();
    }
    const loadLogo = () => {
        const logo = uploadLogo.current.files[0];
        if (logo.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (uploadLogo.current.files && uploadLogo.current.files.length) {
            setBusinessLogo(URL.createObjectURL(logo));

            // upload the business logo
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", logo);
            payload.append("fileName", "BUSINESS_LOGO");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);

            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                
                // ${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc/upload
                axios.post(`${config.BASE_URL}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("New credentials generated.");
                    setKYCInfo({
                        ...KYCInfo,
                        businessLogoUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }

    const saveProfileInfoHandler = (n) => {
        setKYCInfo({
            ...KYCInfo,
            ...directorInfo,
            userBvnNumber: KYCInfo?.userBvnNumber ?? KYCInfo?.bvnNumber,
            userNinNumber: KYCInfo?.userNinNumber ?? KYCInfo?.ninNumber,
            draft: true,
        });
        const authorization = JSON.parse(sessionStorage.getItem("token"));
         const header = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        const postData = {
            ...KYCInfo,
            ...directorInfo,
            userBvnNumber: KYCInfo?.userBvnNumber ?? KYCInfo?.bvnNumber,
            userNinNumber: KYCInfo?.userNinNumber ?? KYCInfo?.ninNumber,
            firstName: userData?.firstName,
            lastName: userData?.surname,
            email: userData?.email,
            lga: userData?.lga,
            state: userData?.state,
            streetAddress: userData?.address,
            draft: true
        }
        axios.post(`${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`, postData, header).then(resp => {
            toast.success("Data Stored successfully");
            setTab(n + 1);
        }).catch(err => {
            toast.error(err.response);
        });
    }
    const saveContactInfoHandler = () => {
        const payload = {
            address: userData?.address,
            dateOfBirth:  userData?.dateOfBirth,
            email:  userData?.email,
            firstName:  userData?.firstName,
            gender:  userData?.gender,
            phoneNumber:  userData?.phoneNumber,
            state:  userData?.state,
            lga: userData?.lga,
            country: userData?.country,
            surname:  userData?.surname,
            profileId: userData?.profileSubscription?.profileId,
            city:  userData?.city
        };
        
        updateProfile(userData?.id, payload).then(response=>{
            if(response.status){
                sessionStorage.setItem("userData", JSON.stringify(userData));
                toast.success("Profile Updated Successfully");
                setTab(1);
            }
        }).catch(error=>{
            toast.error("An error occured while updating profile");
        });
        
        // setKYCInfo({
        //     ...KYCInfo,
        //     draft: true
        // });
        // const authorization = JSON.parse(sessionStorage.getItem("token"));
        //  const header = {
        //     headers: {
        //         // 'Content-Type': 'multipart/form-data',
        //         authorization: authorization,
        //         'CLIENT-ID': 'WAYAQUICK',
        //         'CLIENT-TYPE': 'CORPORATE',
        //     }
        // }
        // axios.post(`${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`, KYCInfo, header).then(resp => {
        //     toast.success("Data Stored successfully");
        //     setTab(1);
        // }).catch(err => {
        //     toast.error(err.response);
        // });
    }
    const saveBankInfoHandler = () => {
        setKYCInfo({
            ...KYCInfo,
            draft: true
        });
        const authorization = JSON.parse(sessionStorage.getItem("token"));
         const header = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        axios.post(`${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`, KYCInfo, header).then(resp => {
            toast.success("Data Stored successfully");
            setTab(4);
        }).catch(err => {
            toast.error(err.response);
        });
    }
    // const uploadCertOfInc = () => {
    //     uploadCertOfIncRef.current.click();
    // }
    // const uploadCacLt = () => {
    //     uploadCacLtRef.current.click();
    // }
    const gotoSumaryTab = () => {
        setTab(5);
    }
    const saveAndploadDocs = () => {
        setKYCInfo({
            ...KYCInfo,
            ...directorInfo,
            userBvnNumber: KYCInfo?.userBvnNumber ?? KYCInfo?.bvnNumber,
            userNinNumber: KYCInfo?.userNinNumber ?? KYCInfo?.ninNumber,
            draft: true
        });
        const authorization = JSON.parse(sessionStorage.getItem("token"));
         const header = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        const postData = {
            userBvnNumber: KYCInfo?.userBvnNumber ?? KYCInfo?.bvnNumber,
            userNinNumber: KYCInfo?.userNinNumber ?? KYCInfo?.ninNumber,
            firstName: userData?.firstName,
            lastName: userData?.surname,
            email: userData?.email,
            lga: userData?.lga,
            city: userData?.city,
            country: userData?.country,
            state: userData?.state,
            streetAddress: userData?.address,
            draft: true,
            ...KYCInfo,
            ...directorInfo,
        }
        axios.post(`${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`, postData, header).then(resp => {
            toast.success("Data Stored successfully");
            // Since we are now on the final tab, Setting the draft status to false is key
            setKYCInfo({
                ...KYCInfo,
                draft: false
            });
            gotoSumaryTab();
        }).catch(err => {
            toast.error(err.response);
        });
    }
    const saveNIN = () => {
        // setKYCInfo({
        //     ...KYCInfo,
        //     draft: true
        // });
        const authorization = JSON.parse(sessionStorage.getItem("token"));
         const header = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        const postData = {
            userBvnNumber: KYCInfo?.userBvnNumber ?? KYCInfo?.bvnNumber,
            userNinNumber: KYCInfo?.userNinNumber ?? KYCInfo?.ninNumber,
            firstName: userData?.firstName,
            lastName: userData?.surname,
            email: userData?.email,
            lga: userData?.lga,
            city: userData?.city,
            country: userData?.country,
            state: userData?.state,
            streetAddress: userData?.address,
            draft: true,
            ...KYCInfo,
            ...directorInfo,
        }
        axios.post(`${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`, postData, header).then(resp => {
            toast.success("Data Stored successfully");
            // Since we are now on the final tab, Setting the draft status to false is key
            setKYCInfo({
                ...KYCInfo,
                draft: false
            });
            jumpToTab(2);
        }).catch(err => {
            toast.error(err.response);
        });
    }
    const jumpToTab = (tabToGo) => {
        setTab(tabToGo)
    }
    const finalKYCSubmitHandler = () => {
        // setKYCInfo({
        //     ...KYCInfo,
        //     draft: false
        // });
        const authorization = JSON.parse(sessionStorage.getItem("token"));
         const header = {
            headers: {
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }
        // LOOP through KYCInfo objject to see if any field is empty/null
        let _errorData = []
        for (const key in KYCInfo){
            if(KYCInfo.hasOwnProperty(key)){
                const value = KYCInfo[key];
                if (!value && key !== "draft" && key !== "twitterUrl" 
                    && key !== "lastName" && key !== "ninApproved" && key !== "bvnApproved"
                    && key !== "facebookUrl" && key !== "instagramUrl" 
                    && key !== "usePaymentLink" && key !== "cacItCertificateUrl" 
                    && key !== "cacBnCertificateUrl" && key !== "businessNameCertificateUrl" 
                    && key !== "reasonForRejection" && key !== "deleted" && key !== "deletedBy" 
                    && key !== "dateDeleted" && key !== "userId" && key !== 'memartCertificateUrl' && key !== 'businessCertificateUrl'
                    && key !== 'businessAccountNumber' && key !== 'businessAccountProvider' && key !== 'businessAccountName'
                    && key !== 'businessLogoUrl' && key !== 'businessNationality' && key !== 'incorporationCertificateUrl' 
                    && key !== 'statusLastModifiedOn' && ( !KYCInfo['usePaymentLink'] ? key !== 'websiteLink' : true ) && ( !KYCInfo['usePaymentLink'] ? key !== 'appLink' : true )
                    && ( KYCInfo['businessType'] ==='NON_REGISTERED_BUSINESS' ? key !== 'businessRegistrationNumber' : true )
                    && ( KYCInfo['businessType'] ==='NON_REGISTERED_BUSINESS' ? key !== 'tin' : true )

                    ) {
                    _errorData.push(`${key} field is required`);
                }
            }
        }
        // alert(JSON.stringify(_errorData))
        const postData = {
            userBvnNumber: KYCInfo?.userBvnNumber ?? KYCInfo?.bvnNumber,
            userNinNumber: KYCInfo?.userNinNumber ?? KYCInfo?.ninNumber,
            firstName: userData?.firstName,
            lastName: userData?.surname,
            email: userData?.email,
            lga: userData?.lga,
            city: userData?.city,
            country: userData?.country,
            state: userData?.state,
            streetAddress: userData?.address,
            draft: false,
            ...KYCInfo,
            ...directorInfo,
        }
        const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
        if (_errorData.length < 1){
            axios.post(`${config.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc/submit?merchantId=${merchantId}`, {}, header).then(resp => {
                toast.success("Data Stored successfully");
                setLoadState(true);
                setTab(9);
                setTimeout(() => {
                    setLoadState(false);
                }, 5000);
            }).catch(err => {
                toast.error(err ?.response ?.data ?.details[0]);
            });
        }else{
            setErrorData(_errorData)
        }
    }

    const uploadMemart = () => {
        const file = memartRef.current.files[0];
        if (file.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (memartRef.current.files && memartRef.current.files.length) {
            // upload memart document
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", file);
            payload.append("fileName", "MEMART");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);
            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                axios.post(`${config.BASE_URL}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("Uploaded successfully");
                    setKYCInfo({
                        ...KYCInfo,
                        memartCertificateUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }
    const uploadDirectorPic = () => {
        const file = dirPicRef.current.files[0];
        if (file?.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (dirPicRef.current.files && dirPicRef.current.files.length) {
            // upload memart document
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", file);
            payload.append("fileName", "DIRECTORPHOTO");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);
            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                axios.post(`${config.BASE_URL}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("Uploaded successfully");
                    setDirectoInfo({
                        ...directorInfo,
                        directorPhotoUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }
    const uploadDirectorNIN = () => {
        const file = dirNinRef.current.files[0];
        if (file.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (dirNinRef.current.files && dirNinRef.current.files.length) {
            // upload memart document
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", file);
            payload.append("fileName", "DIRECTORNIN");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);
            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                axios.post(`${config.BASE_URL}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("Uploaded successfully");
                    setDirectoInfo({
                        ...directorInfo,
                        directorNinImageUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }

    const uploadCertOfInc = () => {
        const file = uploadCertOfIncRef.current.files[0];
        if (file.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (uploadCertOfIncRef.current.files && uploadCertOfIncRef.current.files.length) {
            // upload the business logo
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", file);
            payload.append("fileName", "INCORPORATION_CERTIFICATE");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);
            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                axios.post(`${config.BASE_URL}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("Uploaded successfully");
                    
                    setKYCInfo({
                        ...KYCInfo,
                        incorporationCertificateUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }

    const uploadCacLt = () => {
        const file = uploadCacLtRef.current.files[0];
        if (file.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (uploadCacLtRef.current.files && uploadCacLtRef.current.files.length) {
            // upload the business logo
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", file);
            payload.append("fileName", "CAC_IT");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);
            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                axios.post(`${config.BASE_URL}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("Uploaded successfully");
                    
                    setKYCInfo({
                        ...KYCInfo,
                        cacltCertificateUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }

    const uploadCertOfBusName = () => {
        const file = uploadCertOfBusNameRef.current.files[0];
        if (file.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (uploadCertOfBusNameRef.current.files && uploadCertOfBusNameRef.current.files.length) {
            // upload the business logo
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", file);
            payload.append("fileName", "BUSINESS_NAME_CERTIFICATE");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);
            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                axios.post(`${config.BASE_URL}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("Uploaded successfully");
                    
                    setKYCInfo({
                        ...KYCInfo,
                        businessNameCertificateUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }

    const uploadBNCac = () => {
        const file = uploadBNCacRef.current.files[0];
        if (file.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (uploadBNCacRef.current.files && uploadBNCacRef.current.files.length) {
            // upload the business logo
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", file);
            payload.append("fileName", "CAC_BN");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);
            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                axios.post(`${config.BASE_URL}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("Uploaded successfully");
                    
                    setKYCInfo({
                        ...KYCInfo,
                        cacBnCertificateUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }

    // *****************************

    const uploadAndUpdateBusinessAddr = () => {
        const file = businessAddressProveTypeRef.current.files[0];
        if (file.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (businessAddressProveTypeRef.current.files && businessAddressProveTypeRef.current.files.length) {
            // upload the business logo
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", file);
            payload.append("fileName", "BUSINESS_ADDRESS_PROVE");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);
            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                axios.post(`${config.BASE_URL}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("Uploaded successfully");
                    
                    setKYCInfo({
                        ...KYCInfo,
                        businessAddressProveUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }
    
    const uploadAndUpdateID = () => {
        const file = identificationTypeRef.current.files[0];
        if (file.size > 1536000) {
            toast.error("Image too large. Can't support more than 1.5MB");
        }else if (identificationTypeRef.current.files && identificationTypeRef.current.files.length) {
            // upload the business logo
            const authorization = JSON.parse(sessionStorage.getItem("token"));
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    authorization: authorization,
                    'CLIENT-ID': 'WAYAQUICK',
                    'CLIENT-TYPE': 'CORPORATE',
                }
            }
            let payload = new FormData();
            payload.append("file", file);
            payload.append("fileName", "ID_CARD");
            payload.append("userId", JSON.parse(sessionStorage.getItem("userData")).id);
            const merchantId = JSON.parse(sessionStorage.getItem("merchantId"));
            setTimeout(() => {
                axios.post(`${config.BASE_WAYA}/file-resource-service/upload/others`, payload, header).then(resp => {
                    toast.success("Uploaded successfully");
                    
                    setKYCInfo({
                        ...KYCInfo,
                        identificationUrl: resp.data.data
                    })
                }).catch(err => {
                    toast.error(err.response);
                });
            }, 500);
        }
    }

    const validateId = async () => {
        if (KYCInfo?.idNumber?.length < 1) return toast.error('ID number cannot be empty!');
        const authorization = JSON.parse(sessionStorage.getItem("token"));
        const header = {
            headers: {
                'content-type': 'application/json',
                authorization: authorization,
                'CLIENT-ID': 'WAYAQUICK',
                'CLIENT-TYPE': 'CORPORATE',
            }
        }

        
         const userData =   JSON.parse(sessionStorage.getItem("userData"))
            let res;
    if (KYCInfo?.identificationType === 'INTERNATIONAL_PASSPORT') {
      setTimeout(() => {
        axios.post(`${config.BASE_WAYA}/kyc-servicev2/no-auth/verifyCacRegNo2/INTERNATIONAL_PASSPORT?lastName=${userData?.surname}&regNo=${KYCInfo?.idNumber}`, {}, header).then(resp => {
            toast.success("verified");
        }).catch(err => {
            toast.error(err.response?.data.message);
            setKYCInfo({...KYCInfo, idNumber: '' })
        });
    }, 500);
    } else if (KYCInfo?.identificationType === 'NATIONAL_ID') {
        setTimeout(() => 
        {
            axios.post(`${config.BASE_WAYA}/kyc-servicev2/no-auth/verifyCacRegNo2/NATIONAL_ID?lastName=${userData?.surname}&regNo=${KYCInfo?.idNumber}`, {}, header).then(resp => {
                toast.success("verified");
            }).catch(err => {
                toast.error(err.response?.data.message);
                setKYCInfo({...KYCInfo, idNumber: '' })
            });
        }, 500);
    } else if (KYCInfo?.identificationType === 'DRIVER_LICENCE') {
        setTimeout(() => 
        {
            axios.post(`${config.BASE_WAYA}//no-auth/verifyCacRegNo2/driverLicence?dob=${userData?.dob}&firstName=${userData?.firstName}&lastName=${userData?.surname}&regNo=${userData?.idNumber}`, {}, header).then(resp => {
                toast.success("Verified");
            toast.success("Uploaded successfully");
        }).catch(err => {
            toast.error(err.response?.data.message);
            setKYCInfo({...KYCInfo, idNumber: '' })
        });
    }, 500);
    } else {
     
    }
    
    }
 

    return (
        <div className="py-10">
            <ToastContainer />
            <div className="w-full sm:w-3/4 mx-auto grid grid-cols-6 px-5 pt-5 pb-10 rounded-xl shadow border border-gray-50 relative ...">
            <div onClick={() => jumpToTab(0)} className={`${tab >= 0 ? "active" : ""} kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}><span onClick={() => jumpToTab(0)} className="hidden sm:flex cursor-pointer">Step 1 </span></div>
                <div
                    onClick={() => jumpToTab(1)} 
                    className={`${tab > 0 ? "active" : ""} kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}><span onClick={() => jumpToTab(1)} className="hidden sm:flex cursor-pointer">Step 2 </span></div>
                <div onClick={() => jumpToTab(2)} className={`${tab > 1 ? "active" : ""} kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}><span onClick={() => jumpToTab(2)} className="hidden sm:flex cursor-pointer">Step 3</span></div>
                {/* <div onClick={() => jumpToTab(3)} className={`${tab > 2 ? "active" : ""} kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}><span onClick={() => jumpToTab(3)} className="hidden sm:flex cursor-pointer">Bank Details</span></div> */}
                <div onClick={() => jumpToTab(4)} className={`${tab > 3 ? "active" : ""} kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}><span onClick={() => jumpToTab(4)} className="hidden sm:flex cursor-pointer">Step 4</span></div>
                <div onClick={() => jumpToTab(5)} className={`${tab > 4 ? "active" : ""} kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}><span onClick={() => jumpToTab(5)} className="hidden sm:flex cursor-pointer">Step 5</span></div>
                <div onClick={() => jumpToTab(6)} className={`${tab > 4 ? "active" : ""} kyc-navigation cursor-pointer text-sm relative flex items-center justify-center`}><span onClick={() => jumpToTab(6)} className="hidden sm:flex cursor-pointer">Summary</span></div>
            </div>

            <div className="grid lg:grid-cols-1 xl:grid-cols-7 w-full mt-5 mx-auto gap-5 px-4 md:px-20">
                {
                    tab === 0 ?
                    <>
                                <div className="w-full pl-5 sm:pl-0 col-span-2 flex flex-col items-center"></div>
                                <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
                                    <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
                                        <h4 className="font-semibold text-lg text-gray-600">Update Profile</h4>
                                        {/* <p className="text-gray-500 text-sm">We need a few more details about you and your business to give you the best service.</p> */}
                                        <div className="mt-8">
                                            <label className="text-sm font-medium">First Name<span className="text-red-500">*</span></label>
                                            <input value={userData?.firstName} onChange={(e)=>{
                                                // setUserData({
                                                //     ...userData,
                                                //     email: e.target.value
                                                // })
                                            }} disabled type="text" placeholder="ladejobiblessing@gmail.com" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-8">
                                            <label className="text-sm font-medium">Last Name<span className="text-red-500">*</span></label>
                                            <input value={userData?.surname} onChange={(e)=>{
                                                // setUserData({
                                                //     ...userData,
                                                //     email: e.target.value
                                                // })
                                            }} disabled type="text" placeholder="ladejobiblessing@gmail.com" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-8">
                                            <label className="text-sm font-medium">Date of birth<span className="text-red-500">*</span></label>
                                            <input value={userData?.dateOfBirth} onChange={(e)=>{
                                                setUserData({
                                                    ...userData,
                                                    dateOfBirth: e.target.value
                                                })
                                            }} type="date" placeholder="ladejobiblessing@gmail.com" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-8">
                                            <label className="text-sm font-medium">Gender<span className="text-red-500">*</span></label>
                                            <select
                                             onChange={(e)=>setUserData({
                                                ...userData,
                                                gender: e.target.value
                                            })}
                                            value={userData?.gender}
                                            className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm">
                                                <option value=''>Select...</option>
                                                <option value='MALE'>MALE</option>
                                                <option value='FEMALE'>FEMALE</option>
                                            </select>                                             
                                        </div>
                                        <div className="mt-8">
                                            <label className="text-sm font-medium">Email Address<span className="text-red-500">*</span></label>
                                            <input value={userData?.email} onChange={(e)=>{
                                                setUserData({
                                                    ...userData,
                                                    email: e.target.value
                                                })
                                            }} type="email" placeholder="ladejobiblessing@gmail.com" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">Phone Number <span className="text-red-500">*</span></label>
                                             <input value={userData?.phoneNumber} onChange={(e)=>{
                                                setUserData({
                                                    ...userData,
                                                    phoneNumber: e.target.value
                                                })
                                            }} type="number" placeholder="2348148331698" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        
                                            {/* <PhoneInput
                                                country={'ng'}
                                                value={KYCInfo?.businessPhoneNumber}
                                                onChange={phone => setPhone(phone)}
                                                className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm"
                                            /> */}
                                        </div>

                                        <h5 className="text-md mt-4 font-semibold">Address</h5>

                                        <div className="mt-2">
                                            <label className="text-sm font-medium">Country <span className="text-red-500">*</span></label>
                                            <input value={userData?.country ?? KYCInfo?.country} onChange={(e)=>{
                                                setUserData({
                                                    ...userData,
                                                    country: e.target.value
                                                })
                                            }} type="text" placeholder="Nigeria" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">State <span className="text-red-500">*</span></label>
                                            <input value={userData?.state} onChange={(e)=>{
                                                setUserData({
                                                    ...userData,
                                                    state: e.target.value
                                                })
                                            }} type="text" placeholder="Lagos" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">Local Government Area <span className="text-red-500">*</span></label>
                                            <input value={userData?.lga ?? KYCInfo?.lga} onChange={(e)=>{
                                                setUserData({
                                                    ...userData,
                                                    lga: e.target.value
                                                })
                                            }} type="text" placeholder="Kosofe" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">City <span className="text-red-500">*</span></label>
                                            <input value={userData?.city ?? KYCInfo?.city} onChange={(e)=>{
                                                setUserData({
                                                    ...userData,
                                                    city: e.target.value
                                                })
                                            }} type="text" placeholder="Kosofe" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        {/* <div className="mt-2">
                                            <label className="text-sm font-medium">City <span className="text-red-500">*</span></label>
                                            <input value={KYCInfo?.city} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    city: e.target.value
                                                })
                                            }} type="text" placeholder="Ojota" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div> */}
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">Street Address <span className="text-red-500">*</span></label>
                                            <input value={userData?.address} onChange={(e)=>{
                                                setUserData({
                                                    ...userData,
                                                    address: e.target.value
                                                })
                                            }} type="text" placeholder="99 Sanwolu street" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>



                                        {/* <h4 className="font-semibold text-lg text-gray-600 mt-8">How you wish to accept payment</h4> */}

                                        {/* <FormControl>
                                            <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                                            <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={KYCInfo?.usePaymentLink===true ? "yes" : "no" }
                                                onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        usePaymentLink: e.target.value==="yes" ? true : false
                                                    })
                                                }}
                                                >

                                                <FormControlLabel value={"no"} control={<Radio />} label="Without Website/App" />
                                                {
                                                    !KYCInfo?.usePaymentLink && (
                                                        <>
                                                        <div className="my-3 pl-4 flex items-center gap-2">
                                                            <input type="checkbox" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                            <label className="text-sm font-medium"> Receive payments from your customers in under 5 minutes using Wayapaychats payment Links<span className="text-red-500"></span></label>
                                                        </div>
                                                        <div className="my-3 pl-4 flex items-center gap-2">
                                                            <input type="checkbox" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                            <label className="text-sm font-medium"> You can subimt your website/app later if you wish to use it to accept payments.<span className="text-red-500"></span></label>
                                                        </div>
                                                        </>
                                                    )
                                                }
                                                
                                                
                                                <FormControlLabel value={"yes"} control={<Radio />} label="With Website/App" />

                                                {
                                                    KYCInfo?.usePaymentLink && (
                                                        <>
                                                            <div className="my-3 pl-6">
                                                            <label className="text-sm font-medium"> Accept payment on website.<span className="text-red-500"></span></label>
                                                            <input value={KYCInfo?.websiteLink} onChange={(e)=>{
                                                                setKYCInfo({
                                                                    ...KYCInfo,
                                                                    websiteLink: e.target.value
                                                                })
                                                            }} type="text" placeholder="Enter website URL/Link" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                                        </div>
                                                        <div className="my-3 pl-6">
                                                            <label className="text-sm font-medium"> Accept payment on App.<span className="text-red-500"></span></label>
                                                            <input value={KYCInfo?.appLink} onChange={(e)=>{
                                                                setKYCInfo({
                                                                    ...KYCInfo,
                                                                    appLink: e.target.value
                                                                })
                                                            }} type="text" placeholder="Enter website Link" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                                        </div>
                                                        </>
                                                    )
                                                }
                                            
                                            </RadioGroup>
                                        </FormControl> */}
                                        {/* <div className="my-3 flex items-center gap-2">
                                            <input type="radio" onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    usePaymentLink: true
                                                })
                                            }} name="usePaymentLink" value="true" checked="checked" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                            <label className="text-sm font-medium"> Without Website/App <span className="text-red-500"></span></label>
                                        </div> */}
                                        
                                        {/* <div className="my-3 flex items-center gap-2">
                                            <input type="radio" onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    usePaymentLink: false
                                                })
                                            }} name="usePaymentLink" value="false" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                            <label className="text-sm font-medium"> With Website/App <span className="text-red-500"></span></label>
                                        </div> */}
                                        

                                        {/* <p className="pl-6 text-sm">We need to verify your website/app to provide you the live API KEYS. It should contain:</p>

                                        <div className="flex pl-6 flex-wrap justify-between">
                                            <li className="text-xs">About Us</li>
                                            <li className="text-xs">Contact Us</li>
                                            <li className="text-xs">Pricing</li>
                                            <li className="text-xs">Privacy policy</li>
                                            <li className="text-xs">Terms & conditions</li>
                                            <li className="text-xs">Cancellation/Refund policy</li>
                                        </div>

                                        <h4 className="font-semibold text-xl text-gray-600 mt-8">Social Media URL</h4>

                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Facebook<span className="text-red-500"></span></label>
                                            <input value={KYCInfo?.facebookUrl} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    facebookUrl: e.target.value
                                                })
                                            }} type="text" placeholder="https://www.facebook.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Instagram<span className="text-red-500"></span></label>
                                            <input value={KYCInfo?.instagramUrl} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    instagramUrl: e.target.value
                                                })
                                            }} type="text" placeholder="https://www.instagram.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Twitter<span className="text-red-500"></span></label>
                                            <input value={KYCInfo?.twitterUrl} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    twitterUrl: e.target.value
                                                })
                                            }} type="text" placeholder="https://www.twitter.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div> */}

                                        {/* <h4 className="font-semibold text-xl text-gray-600 mt-8">Registered Address</h4>
                                        <p className="text-gray-500 text-sm">We need your registered address for offline communication</p>

                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Country<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Nigeria" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> State<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Lagos" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Local Government<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Kosofe" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> City<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Ojota" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Street Address<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="99 Sanwolu street" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>

                                        <h4 className="font-semibold text-xl text-gray-600 mt-8">Office Address</h4>
                                        <p className="text-gray-500 text-sm flex items-center">
                                            <input type="checkbox" />
                                            Check the box to use registered address
                                        </p>

                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Country<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Nigeria" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> State<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Lagos" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Local Government<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Kosofe" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> City<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Ojota" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Street Address<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="99 Sanwolu street" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div> */}
                                    </div>
                                    <button onClick={saveContactInfoHandler} className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm">
                                        Save & Continue
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="w-full col-span-2 flex flex-col items-center">
                                <Flex vertical gap="small">
                                   <Progress strokeColor="#ff6700" showInfo={false} strokeLinecap="butt" type="circle" percent={15} />
                                   <h6>1 of 4 complete</h6>
                                </Flex>

                                    <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                                        <button className="absolute -right-3 -top-3">
                                            <CloseIcon />
                                        </button>
                                        <h5 className="text-xl font-semibold">Download our App</h5>
                                        <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">Download our mobile application for better experience and to receive custom notifications in real time. </p>
                                        <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
                                            <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                                                <button className="mx-2"><AndroidIcon /></button>
                                                <button className="mx-2"><IOSIcon /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> :
                            tab === 1 ?
                    <>
            
            <div className="w-full pl-5 sm:pl-0 col-span-2 flex flex-col items-center"/>
    
            <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
                <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
                    <h4 className="font-semibold text-lg text-gray-600">Contact Verification</h4>
                    <table className='table-auto'>
              <thead>
                <tr>
                  <th>Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td className="p-3">Phone Number Verification</td>
                      <td>
                        <div
                          onClick={() => {
                            // setShowAlertType('verifyPhone');
                            setIsEmail(false);
                            handleUpdatePhone();
                          }}
                          className={`action flex flex-row ${
                            phone?.status === 'APPROVED'
                              ? 'btn disabled'
                              : 'btn'
                          } my-2 cursor-pointer`}
                        >
                          <ArrowForward />
                          <span>
                            {phone?.status === 'APPROVED'
                              ? 'Verified'
                              : 'Verify'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            phone?.status === 'APPROVED'
                              ? 'text-success px-2'
                              : 'status-pending px-2'
                          }
                        >
                          <span>{phone?.status}</span>
                        </div>
                      </td>
                </tr>
                <tr>
                <td className="p-3">Email Verification</td>
                      <td>
                        <div
                          onClick={() => {
                            setIsEmail(true);
                            handleUpdateEmail();
                            // setShowAlertType('verifyPhone');
                            // setShowAlert(true);
                          }}
                          style={{ pointerEvents: email?.status === 'APPROVED' ? 'none' : ''}}
                          className={`action cursor-pointer ${
                            email?.status === 'APPROVED'
                              ? 'btn disabled'
                              : 'btn'
                          } my-2`}
                        >
                          <ArrowForward />
                          <span>
                            {email?.status === 'APPROVED'
                              ? 'Verified'
                              : 'Verify'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className={
                            email?.status === 'APPROVED'
                            ? 'text-success px-2'
                            : 'status-pending px-2'
                          }
                        >
                          <span>{email?.status}</span>
                        </div>
                      </td>
                </tr>
                <tr>
                <td className="p-3">NIN Verification</td>
                <td>
                    <input value={KYCInfo?.userNinNumber ?? KYCInfo.bvnNumber} onChange={
                        (e)=> setKYCInfo({
                            ...KYCInfo,
                            userNinNumber: e.target.value
                        })
                    } type="text" placeholder="ID Number" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                    
                </td>
                {/* <td>
                    <button onClick={validateId} class="flex-shrink-0 border-transparent border-4 text-teal-500 hover:text-teal-800 text-sm py-1 px-2 rounded" type="button">
                      verify
                    </button>
                 </td>    */}
                </tr>
                <tr>
                    <td>Bank Verification Number(BVN)</td>
                    <td>
                    <input value={KYCInfo?.userBvnNumber ?? KYCInfo?.ninNumber} onChange={
                        (e)=> setKYCInfo({
                            ...KYCInfo,
                            userBvnNumber: e.target.value
                        })
                    } type="text" placeholder="BVN" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                    </td>
                </tr>
            </tbody>
            </table>
                                    
                                </div>
                                <button onClick={saveNIN} className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm">
                                    Save & Continue
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z" fill="white" />
                                    </svg>

                                </button>
                            </div>
                            <div className="w-full col-span-2 flex flex-col items-center">
                                {/* <KYCNavigation1Icon /> */}
                                <Flex vertical gap="small">
                                   <Progress strokeColor="#ff6700" showInfo={false} strokeLinecap="butt" type="circle" percent={25} />
                                   <h6>2 of 4 complete</h6>
                                </Flex>

                                <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                                    <button className="absolute -right-3 -top-3">
                                        <CloseIcon />
                                    </button>
                                    <h5 className="text-xl font-semibold">Download our App</h5>
                                    <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">Download our mobile application for better experience and to receive custom notifications in real time. </p>
                                    <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
                                        <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                                            <button className="mx-2"><AndroidIcon /></button>
                                            <button className="mx-2"><IOSIcon /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </>:
                    tab === 2

                        ?
                        <>
          
                    
                            <div className="w-full pl-5 sm:pl-0 col-span-2 flex flex-col items-center">
                                <div className="w-28 h-28 flex items-center justify-center text-white relative bg-gray-200 font-semibold text-lg rounded-full">
                                    {
                                        businessLogo
                                            ?
                                            <img src={businessLogo} alt="" className="w-full h-full rounded-full border border-gray-100" />
                                            :
                                            "RD"
                                    }
                                </div>
                                <h5 className="text-sm mt-3 text-gray-500">Upload business logo</h5>
                                <input type="file" onChange={loadLogo} className="hidden" ref={uploadLogo} />
                                <button onClick={uploadImage} className="px-5 py-2 rounded hover:shadow border border-gray-100 text-sm font-medium mt-2">Upload Image</button>
                            </div>
                            <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
                                <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
                                    <h4 className="font-semibold text-lg text-gray-600">Business Information</h4>
                                    <p className="text-gray-500 text-sm">We need a few more details about you and your business to give you the best service.</p>

                                    <div className="mt-8">
                                        <label className="text-sm font-medium">Business Name <span className="text-red-500">*</span></label>
                                        
                                        <input value={KYCInfo?.businessName} onChange={(e)=>{
                                            setKYCInfo({
                                                ...KYCInfo,
                                                businessName: e.target.value
                                            })
                                        }} type="text" placeholder="Thexagon" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                    </div>
                                    <div className="mt-8">
                                        <label className="text-sm font-medium">Business Email <span className="text-red-500">*</span></label>
                                        
                                        <input value={KYCInfo?.businessEmail} onChange={(e)=>{
                                            setKYCInfo({
                                                ...KYCInfo,
                                                businessEmail: e.target.value
                                            })
                                        }} type="email" placeholder="thexagon@yopmail.com" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                    </div>
                                    <div className="mt-8">
                                        <label className="text-sm font-medium">Business Phone <span className="text-red-500">*</span></label>
                                        
                                        <input value={KYCInfo?.businessPhoneNumber} onChange={(e)=>{
                                            setKYCInfo({
                                                ...KYCInfo,
                                                businessPhoneNumber: e.target.value
                                            })
                                        }} type="text" placeholder="Thexagon" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                    </div>
                                    <div className="mt-2">
                                        <label className="text-sm font-medium">Business Description <span className="text-red-500">*</span></label>
                                        
                                        <input value={KYCInfo?.businessDescription} type="text" onChange={(e)=>{
                                            setKYCInfo({
                                                ...KYCInfo,
                                                businessDescription: e.target.value
                                            })
                                        }} placeholder="Specialized in software development" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                    </div>
                                    <div className="mt-2">
                                        <label className="text-sm font-medium">Type of Industry <span className="text-red-500">*</span></label>
                                        
                                        <Select value={
                                            {label: industryList?.filter(elem=> elem.value===KYCInfo?.businessIndustry)[0]?.label, value: industryList?.filter(elem=> elem.value===KYCInfo?.businessIndustry)[0]?.value}
                                        } onChange={(e)=>{
                                            const filteredIndustry = industryList?.filter(elem=> elem.value===e.value)[0];
                                            // const filteredIndustryObj = industryListObj?.filter(elem => elem.id === e.value)[0];

                                            setKYCInfo({
                                                ...KYCInfo,
                                                businessIndustry: filteredIndustry.value
                                            })
                                        }} className="w-full h-10 rounded-lg border border-gray-200 text-sm" options={industryList} placeholder={<>Specialized in software development</>} />
                                        {/* <input type="" placeholder="Specialized in software development" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" /> */}
                                    </div>
                                    <div className="mt-2">
                                        <label className="text-sm font-medium">Type of Business <span className="text-red-500">*</span></label>
                                        <Select value={
                                            {value: KYCInfo?.businessType, label: KYCInfo?.businessType}
                                        } className="w-full h-10 rounded-lg border border-gray-200 text-sm" options={businessTypes} onChange={(e)=>{
                                            setKYCInfo({
                                                ...KYCInfo,
                                                businessType: e.value
                                            })
                                        }} placeholder={<>Registered Business</>} />
                                        {/* <input type="" placeholder="Specialized in software development" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" /> */}
                                    </div>
                                    <p className="text-gray-400 text-xs mt-3">Upload valid business registration documents and get access to full features. <span className="text-primary-theme underline">Learn more.</span></p>
                                </div>
                                {KYCInfo?.businessType === 'REGISTERED_BUSINESS' && (
                                    <div className="mt-2">
                                    <label className="text-sm font-medium">Type of registration <span className="text-red-500">*</span></label>
                                    
                                    <Select value={
                                        registrationTypes.filter(regType=> regType.value===KYCInfo?.businessRegistrationType)[0]
                                    } onChange={(e)=>{
                                        setKYCInfo({
                                            ...KYCInfo,
                                            businessRegistrationType: e.value
                                        });
                                    }} className="w-full h-10 rounded-lg border border-gray-200 text-sm" options={registrationTypes} placeholder={<>Type of registration</>} />

                                </div>
                                )}
                                {KYCInfo?.businessType === 'REGISTERED_BUSINESS' && (
                                        <>
                                        <div className="mt-3">
                                        <label className="text-sm font-medium">Registration Number<span className="text-red-500">*</span></label>
                                        <input value={KYCInfo?.businessRegistrationNumber} onChange={
                                            (e)=> setKYCInfo({
                                                ...KYCInfo,
                                                businessRegistrationNumber: e.target.value
                                            })
                                        } type="text" placeholder="877655" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                    </div>
                                    <h4 className="font-semibold text-lg text-gray-600 mt-8">How you wish to accept payment</h4>

                                        <FormControl>
                                            <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                                            <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={KYCInfo?.usePaymentLink===true ? "yes" : "no" }
                                                onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        usePaymentLink: e.target.value==="yes" ? true : false
                                                    })
                                                }}
                                                >

                                                <FormControlLabel value={"no"} control={<Radio />} label="Without Website/App" />
                                                {/* ********* */}
                                                {
                                                    !KYCInfo?.usePaymentLink && (
                                                        <>
                                                        <div className="my-3 pl-4 flex items-center gap-2">
                                                            <input type="checkbox" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                            <label className="text-sm font-medium"> Receive payments from your customers in under 5 minutes using Wayapaychats payment Links<span className="text-red-500"></span></label>
                                                        </div>
                                                        <div className="my-3 pl-4 flex items-center gap-2">
                                                            <input type="checkbox" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                            <label className="text-sm font-medium"> You can subimt your website/app later if you wish to use it to accept payments.<span className="text-red-500"></span></label>
                                                        </div>
                                                        </>
                                                    )
                                                }
                                                
                                                {/* ********* */}
                                                
                                                <FormControlLabel value={"yes"} control={<Radio />} label="With Website/App" />

                                                {/* ******** */}
                                                {
                                                    KYCInfo?.usePaymentLink && (
                                                        <>
                                                            <div className="my-3 pl-6">
                                                            <label className="text-sm font-medium"> Accept payment on website.<span className="text-red-500"></span></label>
                                                            <input value={KYCInfo?.websiteLink} onChange={(e)=>{
                                                                setKYCInfo({
                                                                    ...KYCInfo,
                                                                    websiteLink: e.target.value
                                                                })
                                                            }} type="text" placeholder="Enter website URL/Link" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                                        </div>
                                                        <div className="my-3 pl-6">
                                                            <label className="text-sm font-medium"> Accept payment on App.<span className="text-red-500"></span></label>
                                                            <input value={KYCInfo?.appLink} onChange={(e)=>{
                                                                setKYCInfo({
                                                                    ...KYCInfo,
                                                                    appLink: e.target.value
                                                                })
                                                            }} type="text" placeholder="Enter website Link" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                                        </div>
                                                        </>
                                                    )
                                                }
                                                {/* ****** */}
                                            
                                            </RadioGroup>
                                        </FormControl>
                                        {/* <div className="my-3 flex items-center gap-2">
                                            <input type="radio" onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    usePaymentLink: true
                                                })
                                            }} name="usePaymentLink" value="true" checked="checked" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                            <label className="text-sm font-medium"> Without Website/App <span className="text-red-500"></span></label>
                                        </div> */}
                                        
                                        {/* <div className="my-3 flex items-center gap-2">
                                            <input type="radio" onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    usePaymentLink: false
                                                })
                                            }} name="usePaymentLink" value="false" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                            <label className="text-sm font-medium"> With Website/App <span className="text-red-500"></span></label>
                                        </div> */}
                                        

                                        <p className="pl-6 text-sm">We need to verify your website/app to provide you the live API KEYS. It should contain:</p>

                                        <div className="flex pl-6 flex-wrap justify-between">
                                            <li className="text-xs">About Us</li>
                                            <li className="text-xs">Contact Us</li>
                                            <li className="text-xs">Pricing</li>
                                            <li className="text-xs">Privacy policy</li>
                                            <li className="text-xs">Terms & conditions</li>
                                            <li className="text-xs">Cancellation/Refund policy</li>
                                        </div>

                                        <h4 className="font-semibold text-xl text-gray-600 mt-8">Social Media URL</h4>

                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Facebook<span className="text-red-500"></span></label>
                                            <input value={KYCInfo?.facebookUrl} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    facebookUrl: e.target.value
                                                })
                                            }} type="text" placeholder="https://www.facebook.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Instagram<span className="text-red-500"></span></label>
                                            <input value={KYCInfo?.instagramUrl} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    instagramUrl: e.target.value
                                                })
                                            }} type="text" placeholder="https://www.instagram.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> X<span className="text-red-500"></span></label>
                                            <input value={KYCInfo?.twitterUrl} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    twitterUrl: e.target.value
                                                })
                                            }} type="text" placeholder="https://www.x.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                    {/* <div className="mt-3">
                                        <label className="text-sm font-medium">Tax Identification Number(TIN)<span className="text-red-500">*</span></label>
                                        <input value={KYCInfo?.tin} onChange={
                                            (e)=> setKYCInfo({
                                                ...KYCInfo,
                                                tin: e.target.value
                                            })
                                        } type="text" placeholder="Lagos" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                    </div> */}
                                        </>)}
                                <button onClick={()=>saveProfileInfoHandler(3)} className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm">
                                    Save & Continue
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z" fill="white" />
                                    </svg>

                                </button>
                            </div>
                            <div className="w-full col-span-2 flex flex-col items-center">
                                {/* <KYCNavigation1Icon /> */}
                                <Flex vertical gap="small">
                                   <Progress strokeColor="#ff6700" showInfo={false} strokeLinecap="butt" type="circle" percent={50} />
                                   <h6>3 of 4 complete</h6>
                                </Flex>

                                <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                                    <button className="absolute -right-3 -top-3">
                                        <CloseIcon />
                                    </button>
                                    <h5 className="text-xl font-semibold">Download our App</h5>
                                    <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">Download our mobile application for better experience and to receive custom notifications in real time. </p>
                                    <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
                                        <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                                            <button className="mx-2"><AndroidIcon /></button>
                                            <button className="mx-2"><IOSIcon /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : tab === 4 ?
                        <>
                                    <div className="w-full pl-5 sm:pl-0 col-span-2 flex flex-col items-center"></div>
                                    <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
                                        <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
                                            <h4 className="font-semibold text-lg text-gray-600">Director</h4>
                                            {/* <p className="text-gray-500 text-sm">We need a few more details about you and your business to give you the best service.</p> */}
                                            <div className="mt-8">
                                                <label className="text-sm font-medium">Director First Name<span className="text-red-500">*</span></label>
                                                <input value={directorInfo?.directorFirstName} onChange={(e)=>{
                                                    setDirectoInfo({
                                                        ...directorInfo,
                                                        directorFirstName: e.target.value
                                                    })
                                                }} type="text" placeholder="First Name" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="mt-8">
                                                <label className="text-sm font-medium">Director Last Name<span className="text-red-500">*</span></label>
                                                <input value={directorInfo?.directorLastName} onChange={(e)=>{
                                                    setDirectoInfo({
                                                        ...directorInfo,
                                                        directorLastName: e.target.value
                                                    })
                                                }} type="text" placeholder="lastName" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div>
                                            
                                            <div className="mt-2">
                                                <label className="text-sm font-medium">BVN <span className="text-red-500">*</span></label>
                                                 <input value={directorInfo?.directorBvnNumber} onChange={(e)=>{
                                                    setDirectoInfo({
                                                        ...directorInfo,
                                                        directorBvnNumber: e.target.value
                                                    })
                                                }} type="number" placeholder="22222222280" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            
                                                {/* <PhoneInput
                                                    country={'ng'}
                                                    value={KYCInfo?.businessPhoneNumber}
                                                    onChange={phone => setPhone(phone)}
                                                    className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm"
                                                /> */}
                                            </div>
                                            <div className="mt-8">
                                                <label className="text-sm font-medium">NIN<span className="text-red-500">*</span></label>
                                                <input value={directorInfo?.directorNinNumber} onChange={(e)=>{
                                                    setDirectoInfo({
                                                        ...directorInfo,
                                                        directorNinNumber: e.target.value
                                                    })
                                                }} type="text" placeholder="123456789" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="mt-6">
                                                <label className="text-sm font-medium">Director NIN <span className="text-red-500">*</span></label>
                                                <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                    <input onChange={uploadDirectorNIN} ref={dirNinRef} type="file" className="hidden" />
                                                    <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>dirNinRef.current.click()}>Upload a file</button>

                                                    {
                                                        directorInfo?.directorNinImageUrl && (
                                                        <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                            <p>
                                                                <a href={directorInfo?.directorNinImageUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                <span onClick={()=>{
                                                                    setDirectoInfo({
                                                                        ...directorInfo,
                                                                        directorNinImageUrl: ""
                                                                    })
                                                                }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                            </p>
                                                        </label>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="mt-6">
                                                <label className="text-sm font-medium">Director Photo <span className="text-red-500">*</span></label>
                                                <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                    <input onChange={uploadDirectorPic} ref={dirPicRef} type="file" className="hidden" />
                                                    <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>dirPicRef.current.click()}>Upload a file</button>

                                                    {
                                                        directorInfo?.directorPhotoUrl && (
                                                        <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                            <p>
                                                                <a href={directorInfo?.directorPhotoUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                <span onClick={()=>{
                                                                    setDirectoInfo({
                                                                        ...directorInfo,
                                                                        directorPhotoUrl: ""
                                                                    })
                                                                }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                            </p>
                                                        </label>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            
    
                                            {/* <h5 className="text-md mt-4 font-semibold">Address</h5> */}
    
                                            {/* <div className="mt-2">
                                                <label className="text-sm font-medium">Country <span className="text-red-500">*</span></label>
                                                <input value={KYCInfo?.country} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        country: e.target.value
                                                    })
                                                }} type="text" placeholder="Nigeria" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div> */}
                                            {/* <div className="mt-2">
                                                <label className="text-sm font-medium">State <span className="text-red-500">*</span></label>
                                                <input value={KYCInfo?.state} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        state: e.target.value
                                                    })
                                                }} type="text" placeholder="Lagos" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="mt-2">
                                                <label className="text-sm font-medium">Local Government Area <span className="text-red-500">*</span></label>
                                                <input value={KYCInfo?.lga} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        lga: e.target.value
                                                    })
                                                }} type="text" placeholder="Kosofe" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="mt-2">
                                                <label className="text-sm font-medium">City <span className="text-red-500">*</span></label>
                                                <input value={KYCInfo?.city} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        city: e.target.value
                                                    })
                                                }} type="text" placeholder="Ojota" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="mt-2">
                                                <label className="text-sm font-medium">Street Address <span className="text-red-500">*</span></label>
                                                <input value={KYCInfo?.streetAddress} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        streetAddress: e.target.value
                                                    })
                                                }} type="text" placeholder="99 Sanwolu street" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div>
     */}
    
    
                                            {/* <h4 className="font-semibold text-lg text-gray-600 mt-8">How you wish to accept payment</h4> */}
    
                                            {/* <FormControl>
                                                <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                                                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={KYCInfo?.usePaymentLink===true ? "yes" : "no" }
                                                    onChange={(e)=>{
                                                        setKYCInfo({
                                                            ...KYCInfo,
                                                            usePaymentLink: e.target.value==="yes" ? true : false
                                                        })
                                                    }}
                                                    >
    
                                                    <FormControlLabel value={"no"} control={<Radio />} label="Without Website/App" />
                                                    {
                                                        !KYCInfo?.usePaymentLink && (
                                                            <>
                                                            <div className="my-3 pl-4 flex items-center gap-2">
                                                                <input type="checkbox" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                                <label className="text-sm font-medium"> Receive payments from your customers in under 5 minutes using Wayapaychats payment Links<span className="text-red-500"></span></label>
                                                            </div>
                                                            <div className="my-3 pl-4 flex items-center gap-2">
                                                                <input type="checkbox" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                                <label className="text-sm font-medium"> You can subimt your website/app later if you wish to use it to accept payments.<span className="text-red-500"></span></label>
                                                            </div>
                                                            </>
                                                        )
                                                    }
                                                    
                                                    
                                                    <FormControlLabel value={"yes"} control={<Radio />} label="With Website/App" />
    
                                                    {
                                                        KYCInfo?.usePaymentLink && (
                                                            <>
                                                                <div className="my-3 pl-6">
                                                                <label className="text-sm font-medium"> Accept payment on website.<span className="text-red-500"></span></label>
                                                                <input value={KYCInfo?.websiteLink} onChange={(e)=>{
                                                                    setKYCInfo({
                                                                        ...KYCInfo,
                                                                        websiteLink: e.target.value
                                                                    })
                                                                }} type="text" placeholder="Enter website URL/Link" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                                            </div>
                                                            <div className="my-3 pl-6">
                                                                <label className="text-sm font-medium"> Accept payment on App.<span className="text-red-500"></span></label>
                                                                <input value={KYCInfo?.appLink} onChange={(e)=>{
                                                                    setKYCInfo({
                                                                        ...KYCInfo,
                                                                        appLink: e.target.value
                                                                    })
                                                                }} type="text" placeholder="Enter website Link" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                                            </div>
                                                            </>
                                                        )
                                                    }
                                                
                                                </RadioGroup>
                                            </FormControl> */}
                                            {/* <div className="my-3 flex items-center gap-2">
                                                <input type="radio" onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        usePaymentLink: true
                                                    })
                                                }} name="usePaymentLink" value="true" checked="checked" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                <label className="text-sm font-medium"> Without Website/App <span className="text-red-500"></span></label>
                                            </div> */}
                                            
                                            {/* <div className="my-3 flex items-center gap-2">
                                                <input type="radio" onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        usePaymentLink: false
                                                    })
                                                }} name="usePaymentLink" value="false" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                <label className="text-sm font-medium"> With Website/App <span className="text-red-500"></span></label>
                                            </div> */}
                                            
    
                                            {/* <p className="pl-6 text-sm">We need to verify your website/app to provide you the live API KEYS. It should contain:</p>
    
                                            <div className="flex pl-6 flex-wrap justify-between">
                                                <li className="text-xs">About Us</li>
                                                <li className="text-xs">Contact Us</li>
                                                <li className="text-xs">Pricing</li>
                                                <li className="text-xs">Privacy policy</li>
                                                <li className="text-xs">Terms & conditions</li>
                                                <li className="text-xs">Cancellation/Refund policy</li>
                                            </div>
    
                                            <h4 className="font-semibold text-xl text-gray-600 mt-8">Social Media URL</h4>
    
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> Facebook<span className="text-red-500"></span></label>
                                                <input value={KYCInfo?.facebookUrl} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        facebookUrl: e.target.value
                                                    })
                                                }} type="text" placeholder="https://www.facebook.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> Instagram<span className="text-red-500"></span></label>
                                                <input value={KYCInfo?.instagramUrl} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        instagramUrl: e.target.value
                                                    })
                                                }} type="text" placeholder="https://www.instagram.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> Twitter<span className="text-red-500"></span></label>
                                                <input value={KYCInfo?.twitterUrl} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        twitterUrl: e.target.value
                                                    })
                                                }} type="text" placeholder="https://www.twitter.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div> */}
    
                                            {/* <h4 className="font-semibold text-xl text-gray-600 mt-8">Registered Address</h4>
                                            <p className="text-gray-500 text-sm">We need your registered address for offline communication</p>
    
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> Country<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="Nigeria" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> State<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="Lagos" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> Local Government<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="Kosofe" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> City<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="Ojota" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> Street Address<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="99 Sanwolu street" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
    
                                            <h4 className="font-semibold text-xl text-gray-600 mt-8">Office Address</h4>
                                            <p className="text-gray-500 text-sm flex items-center">
                                                <input type="checkbox" />
                                                Check the box to use registered address
                                            </p>
    
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> Country<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="Nigeria" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> State<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="Lagos" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> Local Government<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="Kosofe" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> City<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="Ojota" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="my-3">
                                                <label className="text-sm font-medium"> Street Address<span className="text-red-500">*</span></label>
                                                <input type="text" placeholder="99 Sanwolu street" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                            </div> */}
                                        </div>
                                        <button onClick={() => saveProfileInfoHandler(4)} className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm">
                                            Save & Continue
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z" fill="white" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="w-full col-span-2 flex flex-col items-center">
                                    <Flex vertical gap="small">
                                       <Progress strokeColor="#ff6700" showInfo={false} strokeLinecap="butt" type="circle" percent={15} />
                                       <h6>1 of 4 complete</h6>
                                    </Flex>
    
                                        <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                                            <button className="absolute -right-3 -top-3">
                                                <CloseIcon />
                                            </button>
                                            <h5 className="text-xl font-semibold">Download our App</h5>
                                            <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">Download our mobile application for better experience and to receive custom notifications in real time. </p>
                                            <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
                                                <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                                                    <button className="mx-2"><AndroidIcon /></button>
                                                    <button className="mx-2"><IOSIcon /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                        tab === 2
                            ?
                            <>
                                <div className="w-full pl-5 sm:pl-0 col-span-2 flex flex-col items-center"></div>
                                <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
                                    <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
                                        <h4 className="font-semibold text-lg text-gray-600">Contact Information</h4>
                                        {/* <p className="text-gray-500 text-sm">We need a few more details about you and your business to give you the best service.</p> */}

                                        <div className="mt-8">
                                            <label className="text-sm font-medium">Email Address<span className="text-red-500">*</span></label>
                                            <input value={KYCInfo?.businessEmail} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    businessEmail: e.target.value
                                                })
                                            }} type="email" placeholder="ladejobiblessing@gmail.com" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">Business Phone Number <span className="text-red-500">*</span></label>
                                             <input value={KYCInfo?.businessPhoneNumber} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    businessPhoneNumber: e.target.value
                                                })
                                            }} type="number" placeholder="2348148331698" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        
                                            {/* <PhoneInput
                                                country={'ng'}
                                                value={KYCInfo?.businessPhoneNumber}
                                                onChange={phone => setPhone(phone)}
                                                className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm"
                                            /> */}
                                        </div>

                                        <h5 className="text-md mt-4 font-semibold">Office Address</h5>

                                        <div className="mt-2">
                                            <label className="text-sm font-medium">Country <span className="text-red-500">*</span></label>
                                            <input value={KYCInfo?.country} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    country: e.target.value
                                                })
                                            }} type="text" placeholder="Nigeria" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">State <span className="text-red-500">*</span></label>
                                            <input value={KYCInfo?.state} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    state: e.target.value
                                                })
                                            }} type="text" placeholder="Lagos" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">Local Government Area <span className="text-red-500">*</span></label>
                                            <input value={KYCInfo?.lga} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    lga: e.target.value
                                                })
                                            }} type="text" placeholder="Kosofe" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">City <span className="text-red-500">*</span></label>
                                            <input value={KYCInfo?.city} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    city: e.target.value
                                                })
                                            }} type="text" placeholder="Ojota" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="mt-2">
                                            <label className="text-sm font-medium">Street Address <span className="text-red-500">*</span></label>
                                            <input value={KYCInfo?.streetAddress} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    streetAddress: e.target.value
                                                })
                                            }} type="text" placeholder="99 Sanwolu street" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                        </div>



                                        <h4 className="font-semibold text-lg text-gray-600 mt-8">How you wish to accept payment</h4>

                                        <FormControl>
                                            <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                                            <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={KYCInfo?.usePaymentLink===true ? "yes" : "no" }
                                                onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        usePaymentLink: e.target.value==="yes" ? true : false
                                                    })
                                                }}
                                                >

                                                <FormControlLabel value={"no"} control={<Radio />} label="Without Website/App" />
                                                {/* ********* */}
                                                {
                                                    !KYCInfo?.usePaymentLink && (
                                                        <>
                                                        <div className="my-3 pl-4 flex items-center gap-2">
                                                            <input type="checkbox" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                            <label className="text-sm font-medium"> Receive payments from your customers in under 5 minutes using Wayapaychats payment Links<span className="text-red-500"></span></label>
                                                        </div>
                                                        <div className="my-3 pl-4 flex items-center gap-2">
                                                            <input type="checkbox" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                                            <label className="text-sm font-medium"> You can subimt your website/app later if you wish to use it to accept payments.<span className="text-red-500"></span></label>
                                                        </div>
                                                        </>
                                                    )
                                                }
                                                
                                                {/* ********* */}
                                                
                                                <FormControlLabel value={"yes"} control={<Radio />} label="With Website/App" />

                                                {/* ******** */}
                                                {
                                                    KYCInfo?.usePaymentLink && (
                                                        <>
                                                            <div className="my-3 pl-6">
                                                            <label className="text-sm font-medium"> Accept payment on website.<span className="text-red-500"></span></label>
                                                            <input value={KYCInfo?.websiteLink} onChange={(e)=>{
                                                                setKYCInfo({
                                                                    ...KYCInfo,
                                                                    websiteLink: e.target.value
                                                                })
                                                            }} type="text" placeholder="Enter website URL/Link" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                                        </div>
                                                        <div className="my-3 pl-6">
                                                            <label className="text-sm font-medium"> Accept payment on App.<span className="text-red-500"></span></label>
                                                            <input value={KYCInfo?.appLink} onChange={(e)=>{
                                                                setKYCInfo({
                                                                    ...KYCInfo,
                                                                    appLink: e.target.value
                                                                })
                                                            }} type="text" placeholder="Enter website Link" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                                        </div>
                                                        </>
                                                    )
                                                }
                                                {/* ****** */}
                                            
                                            </RadioGroup>
                                        </FormControl>
                                        {/* <div className="my-3 flex items-center gap-2">
                                            <input type="radio" onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    usePaymentLink: true
                                                })
                                            }} name="usePaymentLink" value="true" checked="checked" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                            <label className="text-sm font-medium"> Without Website/App <span className="text-red-500"></span></label>
                                        </div> */}
                                        
                                        {/* <div className="my-3 flex items-center gap-2">
                                            <input type="radio" onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    usePaymentLink: false
                                                })
                                            }} name="usePaymentLink" value="false" className="rounded-lg px-2 border border-gray-200 text-sm" />
                                            <label className="text-sm font-medium"> With Website/App <span className="text-red-500"></span></label>
                                        </div> */}
                                        

                                        <p className="pl-6 text-sm">We need to verify your website/app to provide you the live API KEYS. It should contain:</p>

                                        <div className="flex pl-6 flex-wrap justify-between">
                                            <li className="text-xs">About Us</li>
                                            <li className="text-xs">Contact Us</li>
                                            <li className="text-xs">Pricing</li>
                                            <li className="text-xs">Privacy policy</li>
                                            <li className="text-xs">Terms & conditions</li>
                                            <li className="text-xs">Cancellation/Refund policy</li>
                                        </div>

                                        <h4 className="font-semibold text-xl text-gray-600 mt-8">Social Media URL</h4>

                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Facebook<span className="text-red-500"></span></label>
                                            <input value={KYCInfo?.facebookUrl} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    facebookUrl: e.target.value
                                                })
                                            }} type="text" placeholder="https://www.facebook.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Instagram<span className="text-red-500"></span></label>
                                            <input value={KYCInfo?.instagramUrl} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    instagramUrl: e.target.value
                                                })
                                            }} type="text" placeholder="https://www.instagram.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Twitter<span className="text-red-500"></span></label>
                                            <input value={KYCInfo?.twitterUrl} onChange={(e)=>{
                                                setKYCInfo({
                                                    ...KYCInfo,
                                                    twitterUrl: e.target.value
                                                })
                                            }} type="text" placeholder="https://www.twitter.com" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>

                                        {/* <h4 className="font-semibold text-xl text-gray-600 mt-8">Registered Address</h4>
                                        <p className="text-gray-500 text-sm">We need your registered address for offline communication</p>

                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Country<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Nigeria" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> State<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Lagos" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Local Government<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Kosofe" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> City<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Ojota" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Street Address<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="99 Sanwolu street" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>

                                        <h4 className="font-semibold text-xl text-gray-600 mt-8">Office Address</h4>
                                        <p className="text-gray-500 text-sm flex items-center">
                                            <input type="checkbox" />
                                            Check the box to use registered address
                                        </p>

                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Country<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Nigeria" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> State<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Lagos" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Local Government<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Kosofe" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> City<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="Ojota" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div>
                                        <div className="my-3">
                                            <label className="text-sm font-medium"> Street Address<span className="text-red-500">*</span></label>
                                            <input type="text" placeholder="99 Sanwolu street" className="rounded-lg w-full h-10 border border-gray-400 px-2 border border-gray-200 text-sm" />
                                        </div> */}
                                    </div>
                                    <button onClick={saveContactInfoHandler} className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm">
                                        Save & Continue
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="w-full col-span-2 flex flex-col items-center">
                                <Flex vertical gap="small">
                                   <Progress strokeColor="#ff6700" showInfo={false} strokeLinecap="butt" type="circle" percent={75} />
                                   <h6>3 of 4 complete</h6>
                                </Flex>

                                    <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                                        <button className="absolute -right-3 -top-3">
                                            <CloseIcon />
                                        </button>
                                        <h5 className="text-xl font-semibold">Download our App</h5>
                                        <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">Download our mobile application for better experience and to receive custom notifications in real time. </p>
                                        <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
                                            <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                                                <button className="mx-2"><AndroidIcon /></button>
                                                <button className="mx-2"><IOSIcon /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            tab === 3
                                ?
                                <>
                                    <div className="w-full pl-5 sm:pl-0 col-span-2 flex flex-col items-center"></div>
                                    <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
                                        <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
                                            <h4 className="font-semibold text-lg text-gray-600">Bank Details</h4>
                                            <p className="text-gray-500 text-sm">We need your account details to process settlements..</p>


                                            {/* *************** */}

                                                
                                            {/* ************** */}

                                            <div className="mt-8">
                                                <label className="text-sm font-medium">Account Provider <span className="text-red-500">*</span></label>

                                                <Select value={
                                                    {label: supportedBank?.filter(elem=> elem.value===KYCInfo?.businessAccountProvider)[0]?.label, value: supportedBank?.filter(elem=> elem.value===KYCInfo?.businessAccountProvider)[0]?.value}
                                                } onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        businessAccountProvider: e.value
                                                    })
                                                }} className="w-full h-10 rounded-lg border border-gray-200 text-sm" options={supportedBank} placeholder={<>Select your bank provider</>} />

                                                {/* <input value={KYCInfo?.businessAccountProvider} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        businessAccountProvider: e.target.value
                                                    })
                                                }} type="text" placeholder="" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" /> */}
                                            </div>
                                            <div className="mt-3 relative" >
                                                <label className="text-sm font-medium">Account Number <span className="text-red-500">*</span></label>
                                                {/* loading */}
                                                    {
                                                        loading && <span style={{border: "2px solid #000099",borderTop: "2px solid transparent"}} className="w-4 h-4 animate-spin rounded-full absolute right-6 bottom-3 block"></span>
                                                    }
                                                {/* loading */}
                                                <input value={KYCInfo?.businessAccountNumber} onChange={(e)=>{

                                                    // ********
                                                        if(e.target.value.length === 10 && KYCInfo?.businessAccountProvider){
                                                            setLoading(true);
                                                            const payload = {
                                                                accountNumber: e.target.value,
                                                                bankCode: unfilteredBanks?.filter(elem=> elem.bankName===KYCInfo?.businessAccountProvider)[0].bankCode,
                                                                bankName: KYCInfo?.businessAccountProvider
                                                            }
                                                            getAccountName(payload).then(response=>{
                                                                // setAccountName(response.data);
                                                                setKYCInfo({
                                                                    ...KYCInfo,
                                                                    businessAccountName: response.data ? response.data : "",
                                                                    businessAccountNumber: e.target.value
                                                                })
                                                                setLoading(false);
                                                            }).catch(error=>{
                                                                toast.error("Unable to fetch account name");
                                                                setLoading(false);
                                                                setKYCInfo({
                                                                    ...KYCInfo,
                                                                    businessAccountName: ""
                                                                })
                                                            });
                                                        }else if(e.target.value.length > 4 && !KYCInfo?.businessAccountProvider){
                                                            setKYCInfo({
                                                                ...KYCInfo,
                                                                businessAccountName: ""
                                                            })
                                                            toast("Select your bank");
                                                        }else{
                                                            setKYCInfo({
                                                                ...KYCInfo,
                                                                businessAccountName: ""
                                                            })
                                                        }
                                                    // ********
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        businessAccountNumber: e.target.value
                                                    })
                                                }} type="number" placeholder="" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div>
                                            <div className="mt-3">
                                                <label className="text-sm font-medium">Account Name <span className="text-red-500">*</span></label>
                                                <input disabled value={KYCInfo?.businessAccountName} onChange={(e)=>{
                                                    setKYCInfo({
                                                        ...KYCInfo,
                                                        businessAccountName: e.target.value
                                                    })
                                                }} type="text" placeholder="" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                            </div>
                                        </div>
                                        <button onClick={saveBankInfoHandler} className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm">
                                            Save & Continue
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z" fill="white" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="w-full col-span-2 flex flex-col items-center">
                                    <Flex vertical gap="small">
                                        <Progress strokeColor="#ff6700" showInfo={false} strokeLinecap="butt" type="circle" percent={100} />
                                        <h6>4 of 4 complete</h6>
                                    </Flex>

                                        <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                                            <button className="absolute -right-3 -top-3">
                                                <CloseIcon />
                                            </button>
                                            <h5 className="text-xl font-semibold">Download our App</h5>
                                            <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">Download our mobile application for better experience and to receive custom notifications in real time. </p>
                                            <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
                                                <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                                                    <button className="mx-2"><AndroidIcon /></button>
                                                    <button className="mx-2"><IOSIcon /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :

                                tab === 5
                                    ?
                                    <>
                                        <div className="w-full pl-5 sm:pl-0 col-span-2 flex flex-col items-center"></div>
                                        <div className="w-full border border-gray-200 h-auto rounded-lg col-span-3 p-1">
                                            <div className="w-full border border-gray-200 bg-gray-100 rounded-lg px-3 py-2 pb-4">
                                                <h4 className="font-semibold text-lg text-gray-600">Upload Relevant Certificate</h4>
                                                <p className="text-gray-500 text-sm">We need the following information to verify your account (File format supported pdf only).</p>

                                                {/* {KYCInfo?.businessType === 'REGISTERED_BUSINESS' && (
                                                  <div className="mt-2">
                                                    <label className="text-sm font-medium">Type of registration <span className="text-red-500">*</span></label>
                                                    
                                                    <Select value={
                                                        registrationTypes.filter(regType=> regType.value===KYCInfo?.businessRegistrationType)[0]
                                                    } onChange={(e)=>{
                                                        setKYCInfo({
                                                            ...KYCInfo,
                                                            businessRegistrationType: e.value
                                                        });
                                                    }} className="w-full h-10 rounded-lg border border-gray-200 text-sm" options={registrationTypes} placeholder={<>Type of registration</>} />

                                                </div>
                                                )} */}

                                                {
                                                    KYCInfo?.businessRegistrationType==="INCORPORATED_COMPANY" && KYCInfo?.businessType === 'REGISTERED_BUSINESS' &&  (
                                                        <>
                                                            <div className="mt-6">
                                                                <label className="text-sm font-medium">Memart <span className="text-red-500">*</span></label>
                                                                <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                                    <input onChange={uploadMemart} ref={memartRef} type="file" className="hidden" />
                                                                    <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>memartRef.current.click()}>Upload a file</button>

                                                                    {
                                                                        KYCInfo?.memartCertificateUrl && (
                                                                        <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                                            <p>
                                                                                <a href={KYCInfo?.memartCertificateUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                                <span onClick={()=>{
                                                                                    setKYCInfo({
                                                                                        ...KYCInfo,
                                                                                        memartCertificateUrl: ""
                                                                                    })
                                                                                }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                                            </p>
                                                                        </label>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>

                                                            {KYCInfo?.businessType === 'REGISTERED_BUSINESS' && (
                                                                <div className="mt-6">
                                                                <label className="text-sm font-medium">Certficate of Incorporation <span className="text-red-500">*</span></label>
                                                                <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                                    <input onChange={uploadCertOfInc} ref={uploadCertOfIncRef} type="file" className="hidden" />
                                                                    <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>uploadCertOfIncRef.current.click()}>Upload a file</button>

                                                                    {
                                                                        KYCInfo?.incorporationCertificateUrl && (
                                                                        <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                                            <p>
                                                                                <a href={KYCInfo?.incorporationCertificateUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                                <span onClick={()=>{
                                                                                    setKYCInfo({
                                                                                        ...KYCInfo,
                                                                                        incorporationCertificateUrl: ""
                                                                                    })
                                                                                }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                                            </p>
                                                                        </label>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            )}
                                                        </>
                                                    )
                                                }
                                                
                                                {
                                                    KYCInfo?.businessRegistrationType==="INCORPORATED_TRUSTEES" && (
                                                        <div className="mt-6">
                                                            <label className="text-sm font-medium">Form CAC IT/1 <span className="text-red-500">*</span></label>
                                                            <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                                <input onChange={uploadCacLt} ref={uploadCacLtRef} type="file" className="hidden" />
                                                                <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>uploadCacLtRef.current.click()}>Upload a file</button>

                                                                {
                                                                    KYCInfo?.cacltCertificateUrl && (
                                                                    <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                                        <p>
                                                                            <a href={KYCInfo?.cacltCertificateUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                            <span onClick={()=>{
                                                                                setKYCInfo({
                                                                                    ...KYCInfo,
                                                                                    cacltCertificateUrl: ""
                                                                                })
                                                                            }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                                        </p>
                                                                    </label>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    KYCInfo?.businessRegistrationType==="BUSINESS_NAME_REGISTRATION" && (
                                                        <>
                                                            <div className="mt-6">
                                                                <label className="text-sm font-medium">Certificate of Business Name <span className="text-red-500">*</span></label>
                                                                <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                                    <input onChange={uploadCertOfBusName} ref={uploadCertOfBusNameRef} type="file" className="hidden" />
                                                                    <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>uploadCertOfBusNameRef.current.click()}>Upload a file</button>

                                                                    {
                                                                        KYCInfo?.businessNameCertificateUrl && (
                                                                        <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                                            <p>
                                                                                <a href={KYCInfo?.businessNameCertificateUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                                <span onClick={()=>{
                                                                                    setKYCInfo({
                                                                                        ...KYCInfo,
                                                                                        businessNameCertificateUrl: ""
                                                                                    })
                                                                                }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                                            </p>
                                                                        </label>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="mt-6">
                                                                <label className="text-sm font-medium">Form CAC BN 1 <span className="text-red-500">*</span></label>
                                                                <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                                    <input onChange={uploadBNCac} ref={uploadBNCacRef} type="file" className="hidden" />
                                                                    <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>uploadBNCacRef.current.click()}>Upload a file</button>

                                                                    {
                                                                        KYCInfo?.cacBnCertificateUrl && (
                                                                        <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                                            <p>
                                                                                <a href={KYCInfo?.cacBnCertificateUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                                <span onClick={()=>{
                                                                                    setKYCInfo({
                                                                                        ...KYCInfo,
                                                                                        cacBnCertificateUrl: ""
                                                                                    })
                                                                                }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                                            </p>
                                                                        </label>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                                {/* <div className="mt-6">
                                                    <label className="text-sm font-medium">Select types of documents to upload <span className="text-red-500">*</span></label>
                                                    
                                                    <Select value={
                                                        {label: KYCInfo?.businessCertificateType, value: KYCInfo?.businessCertificateType}
                                                    } onChange={(e)=>{
                                                        setKYCInfo({
                                                            ...KYCInfo,
                                                            businessCertificateType: e.value
                                                        })
                                                    }} className="w-full h-10 rounded-lg border border-gray-200 text-sm" options={[
                                                        {label: "MEMART", value: "MEMART"},
                                                        {label: "CAC IT 1", value: "CAC_IT_1"},
                                                        {label: "CAC BN 1", value: "CAC_BN_1"},
                                                        {label: "CERTIFICATE OF INCORPORATION", value: "CERTIFICATE_OF_INCORPORATION"},
                                                        {label: "CERTIFICATE OF BUSINESS_NAME", value: "CERTIFICATE_OF_BUSINESS_NAME"}]} placeholder={<>Type of registration</>} />

                                                    <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                         <input onChange={uploadAndUpdateBusinessRegType} ref={businessCertificateTypeRef} type="file" className="hidden" />
                                                        <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>businessCertificateTypeRef.current.click()}>Upload a file</button>

                                                        {
                                                            KYCInfo?.businessCertificateUrl && (
                                                            <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                                <p>
                                                                    <a href={KYCInfo?.businessCertificateUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                    <span onClick={()=>{
                                                                        setKYCInfo({
                                                                            ...KYCInfo,
                                                                            businessCertificateUrl: ""
                                                                        })
                                                                    }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                                </p>
                                                            </label>
                                                            )
                                                        }
                                                    </div>
                                                </div> */}

                                                {KYCInfo?.businessType === 'REGISTERED_BUSINESS' && (
                                                    <>
                                                    {/* <div className="mt-3">
                                                    <label className="text-sm font-medium">Registration Number<span className="text-red-500">*</span></label>
                                                    <input value={KYCInfo?.businessRegistrationNumber} onChange={
                                                        (e)=> setKYCInfo({
                                                            ...KYCInfo,
                                                            businessRegistrationNumber: e.target.value
                                                        })
                                                    } type="text" placeholder="Nigeria" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                </div> */}
                                                <div className="mt-3">
                                                    <label className="text-sm font-medium">Tax Identification Number(TIN)<span className="text-red-500">*</span></label>
                                                    <input value={KYCInfo?.tin} onChange={
                                                        (e)=> setKYCInfo({
                                                            ...KYCInfo,
                                                            tin: e.target.value
                                                        })
                                                    } type="text" placeholder="098388" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                </div>
                                                    </>)}

                                                {/* <div className="mt-3">
                                                    <label className="text-sm font-medium">Bank Verification Number(BVN)<span className="text-red-500">*</span></label>
                                                    <input value={KYCInfo?.bvn} onChange={
                                                        (e)=> setKYCInfo({
                                                            ...KYCInfo,
                                                            bvn: e.target.value
                                                        })
                                                    } type="text" placeholder="BVN" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                </div> */}
                                                <div className="mt-2">
                                                    <label className="text-sm font-medium">Select Any Type Government Approved ID <span className="text-red-500"></span></label>
                                                    <Select value={
                                                        {label: KYCInfo?.identificationType, value: KYCInfo?.identificationType}
                                                    } onChange={(e)=>{
                                                        setKYCInfo({
                                                            ...KYCInfo,
                                                            identificationType: e.value
                                                        })
                                                    }} className="w-full h-10 rounded-lg border border-gray-200 text-sm" options={[
                                                        // {label: "INTERNATIONAL_PASSPORT", value: "INTERNATIONAL_PASSPORT"},
                                                        {label: "NATIONAL_ID", value: "NATIONAL_ID"},
                                                        // {label: "VOTERS_CARD", value: "VOTERS_CARD"},
                                                    ]} placeholder={<>Type of Identification</>} />
                                                    <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                        <input onChange={uploadAndUpdateID} ref={identificationTypeRef} type="file" className="hidden" />
                                                        <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>identificationTypeRef.current.click()}>Upload a file</button>

                                                        {
                                                            KYCInfo?.identificationUrl && (
                                                            <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                                <p>
                                                                    <a href={KYCInfo?.identificationUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                    <span onClick={()=>{
                                                                        setKYCInfo({
                                                                            ...KYCInfo,
                                                                            identificationUrl: ""
                                                                        })
                                                                    }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                                </p>
                                                            </label>
                                                            )
                                                        }
                                                    </div>
                                                    {/* <div className="mt-3">
                                                    <label className="text-sm font-medium">Enter Id Number<span className="text-red-500"></span></label>
                                                    <div className="flex items-center border-b border-teal-500 py-2">
                                                    <input value={KYCInfo?.idNumber} onChange={
                                                        (e)=> setKYCInfo({
                                                            ...KYCInfo,
                                                            idNumber: e.target.value
                                                        })
                                                    } type="text" placeholder="ID Number" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    <button onClick={validateId} class="flex-shrink-0 border-transparent border-4 text-teal-500 hover:text-teal-800 text-sm py-1 px-2 rounded" type="button">
                                                    verify
                                                    </button>
                                                    </div>
                                                </div> */}
                                                </div>


                                                <div className="mt-2">
                                                    <label className="text-sm font-medium">Proof of business operating address for the company <span className="text-red-500">*</span></label>
                                                    
                                                    <Select value={
                                                        {label: KYCInfo?.businessAddressProveType, value: KYCInfo?.businessAddressProveType}
                                                    } onChange={(e)=>{
                                                        setKYCInfo({
                                                            ...KYCInfo,
                                                            businessAddressProveType: e.value
                                                        })
                                                    }} className="w-full h-10 rounded-lg border border-gray-200 text-sm" options={[
                                                        {label: "UTILITY_BILL", value: "UTILITY_BILL"}
                                                    ]} placeholder={<>Type of registration</>} />

                                                    <div className="h-auto bg-gray-200 border border-gray-400 border-dashed mt-2 px-2 py-3 flex items-center flex-col">
                                                        <input onChange={uploadAndUpdateBusinessAddr} ref={businessAddressProveTypeRef} type="file" className="hidden" />
                                                        <button className="text-xs px-3 py-2 rounded bg-white" onClick={()=>businessAddressProveTypeRef.current.click()}>Upload a file</button>

                                                        {
                                                            KYCInfo?.businessAddressProveUrl && (
                                                            <label className="w-full text-xs mt-2 px-2 bg-white py-2 flex justify-between">Document uploaded 
                                                                <p>
                                                                    <a href={KYCInfo?.businessAddressProveUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>
                                                                    <span onClick={()=>{
                                                                        setKYCInfo({
                                                                            ...KYCInfo,
                                                                            businessAddressProveUrl: ""
                                                                        })
                                                                    }} className="cursor-pointer text-red-500 text-xs mx-4">X</span>
                                                                </p>
                                                            </label>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                {/* <div className="mt-8">
                                                    <label className="text-sm font-medium">Certificate of Incorporation <span className="text-red-500">*</span></label>
                                                    <div className="flex gap-3">
                                                        <input type="text" placeholder="" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                        <input type="file" name="cert_of_inc" ref={cert_of_inc} className="hidden" />
                                                        <button onClick={uploadCertOfInc} className="w-40 flex items-center text-xs px-2 py-1 border border-gray-200 bg-white rounded text-primary-theme">
                                                            <AttachIcon />
                                                            Attach file
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <label className="text-sm font-medium">CAC Application Form <span className="text-red-500">*</span></label>
                                                    <div className="flex gap-3">
                                                        <input type="text" placeholder="" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                        <input type="file" name="cert_of_cac" ref={cert_of_cac} className="hidden" />
                                                        <button onClick={uploadCacLt} className="w-40 flex items-center text-xs px-2 py-1 border border-gray-200 bg-white rounded text-primary-theme">
                                                            <AttachIcon />
                                                            Attach file
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <label className="text-sm font-medium">CAC Registration Certificate Number <span className="text-red-500">*</span></label>
                                                    <input type="number" placeholder="" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                </div> */}
                                            </div>
                                            <button onClick={saveAndploadDocs} className="flex my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm">
                                                Save & Continue
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99992 3.33398L8.82492 4.50898L13.4749 9.16732H3.33325V10.834H13.4749L8.82492 15.4923L9.99992 16.6673L16.6666 10.0007L9.99992 3.33398Z" fill="white" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="w-full col-span-2 flex flex-col items-center">
                                        <Flex vertical gap="small">
                                        <Progress strokeColor="#ff6700" showInfo={false} strokeLinecap="butt" type="circle" percent={100} />
                                        <h6>4 of 4 complete</h6>
                                        </Flex>

                                            <div className="w-full mt-10 relative h-auto flex-col items-center border border-gray-100 flex pt-5 pb-3 rounded-md shadow hover:shadow-xl transition duration-700 hover:bg-gray-50">
                                                <button className="absolute -right-3 -top-3">
                                                    <CloseIcon />
                                                </button>
                                                <h5 className="text-xl font-semibold">Download our App</h5>
                                                <p className="text-gray-500 h-24 mt-4 text-sm px-3 sm:px-10 xl:px-3 text-center">Download our mobile application for better experience and to receive custom notifications in real time. </p>
                                                <div className="w-full px-3 h-auto sm:h-12 mt-1 flex justify-center">
                                                    <div className="xl:grid inline-block flex flex-col justify-center sm:grid-cols-2 xl:grid-cols-2 gap-2">
                                                        <button className="mx-2"><AndroidIcon /></button>
                                                        <button className="mx-2"><IOSIcon /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    tab === 6
                                        ?
                                        <>
                                            <div className="w-4/5 border border-gray-200 h-auto rounded-lg col-span-7 p-1 mx-auto ...">
                                                <div className="w-full border border-gray bg-white rounded-lg px-3 py-2 pb-4">
                                                    <h4 className="font-semibold text-lg text-gray-600">Profile Information</h4>

                                                    {/* <div className="grid grid-cols-2 gap-4">
                                                        <div className="mt-3">
                                                            <label className="text-sm font-medium">Legal business name <span className="text-red-500">*</span></label>
                                                            <input value={KYCInfo?.businessName} type="text" disabled placeholder="Firstname" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                        </div>
                                                        <div className="mt-8 pt-1">
                                                            <label className="text-sm font-medium"> <span className="text-red-500"></span></label>
                                                            <input type="text" disabled placeholder="Lastname" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                        </div>
                                                    </div> */}
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Legal Business Name <span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.businessName} type="text" disabled placeholder="Thexagon Limited" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Business Description <span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.businessDescription} type="text" disabled placeholder="Creative agency to help build your business and sales." className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>

                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Types of Industry <span className="text-red-500">*</span></label>
                                                        <input value={industryList?.filter(elem=> elem.value===KYCInfo?.businessIndustry)[0]?.label}  type="text" disabled placeholder="Information Technology" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Type of Business <span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.businessType} type="text" disabled placeholder="Corporate" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    {/* <div className="mt-3">
                                                        <label className="text-sm font-medium">Legal Business Name <span className="text-red-500">*</span></label>
                                                        <input type="text" disabled placeholder="Thexagon Limited" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Type of Registration <span className="text-red-500">*</span></label>
                                                        <input type="text" disabled placeholder="Limited Liability Company" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div> */}

                                                    <h4 className="font-semibold text-lg text-gray-600 mt-8">Social Media URL</h4>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Facebook <span className="text-red-500"></span></label>
                                                        <input value={KYCInfo?.facebookUrl} type="text" disabled  className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Instagram <span className="text-red-500"></span></label>
                                                        <input value={KYCInfo?.instagramUrl} type="text" disabled className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Twitter <span className="text-red-500"></span></label>
                                                        <input value={KYCInfo?.twitterUrl} type="text" disabled  className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <h4 className="font-semibold text-lg text-gray-600 mt-8">Registered Addresses</h4>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Country<span className="text-red-500">*</span></label>
                                                        <input value={userData.country ?? KYCInfo?.country} type="text" disabled placeholder="Nigeria" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">State<span className="text-red-500">*</span></label>
                                                        <input value={userData.state ?? KYCInfo?.state} type="text" disabled placeholder="Lagos" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Local Government Area<span className="text-red-500">*</span></label>
                                                        <input value={userData?.lga ?? KYCInfo?.lga} type="text" disabled placeholder="Kosofe" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">City<span className="text-red-500">*</span></label>
                                                        <input value={userData?.city ?? KYCInfo?.city} type="text" disabled placeholder="Ojota" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Street Address<span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.streetAddress} type="text" disabled placeholder="5 Ogudu road" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>

                                                    {/* <h4 className="font-semibold text-lg text-gray-600">Bank Details</h4>

                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Account Provider<span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.businessAccountProvider} type="text" disabled placeholder="United Bank of Africa(UBA)" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Accout Number<span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.businessAccountNumber} type="text" disabled placeholder="4773009612" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Account Name<span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.businessAccountName} type="text" disabled placeholder="Richard Daniel" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>

                                                    {/* <h4 className="font-semibold text-lg text-gray-600 mt-8">Upload Identification</h4> */}

                                                    <h4 className="font-semibold text-lg text-gray-600 mt-8">Document Upload</h4>
                                                    { KYCInfo?.businessType === 'REGISTERED_BUSINESS' &&
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Certificate of Incorporation<span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.businessCertificateType} type="text" disabled placeholder="237823-33245-87-57564" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>}
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Proof of business operating address for the company<span className="text-red-500">*</span></label>
                                                        <div className="flex items-center border-b border-teal-500 py-2">
                                                            <input value={KYCInfo?.businessAddressProveType} type="text" disabled placeholder="1033-4383-3434-65654" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                            {KYCInfo?.businessAddressProveUrl && <a href={KYCInfo?.businessAddressProveUrl} target="_blank" className="cursor-pointer text-blue-500 text-xs mx-4">View</a>}
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Bank Verification Number (BVN)<span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.userBvnNumber ?? KYCInfo?.bvnNumber} type="text" disabled placeholder="" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">Valid Id card<span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.identificationType} type="text" disabled placeholder="" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    {KYCInfo?.businessType === 'REGISTERED_BUSINESS' &&
                                                    <div className="mt-3">
                                                        <label className="text-sm font-medium">CAC Registration Certificate Number<span className="text-red-500">*</span></label>
                                                        <input value={KYCInfo?.businessRegistrationType} type="text" disabled placeholder="RC1265375" className="w-full h-10 rounded-lg px-2 border border-gray-200 text-sm" />
                                                    </div>
                                                    }
                                                </div>
                                                <div className="w-full p-5 bg-red-50">
                                                    {
                                                        errorData.map((err, index)=> (
                                                            <p className="text-red-600 text-sm">ERROR {index} - {err}</p>
                                                        ))
                                                    }
                                                </div>
                                                <div className="flex gap-5">
                                                    <button onClick={() => setTab(1)} className="flex px-10 my-1 items-center justify-center py-3 px-4 rounded bg-primary-theme text-white text-sm">
                                                        Edit
                                                    </button>
                                                    <button onClick={finalKYCSubmitHandler} className="flex my-1 items-center justify-center py-3 px-4 rounded bg-green-700 text-white text-sm">
                                                        Submit KYC
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="w-full h-screen bg-black z-30 flex items-center justify-center overlay fixed top-0 left-0">
                                                {
                                                    loadState
                                                        ?
                                                        <div className="bg-white rounded-xl h-auto py-10 px-10 w-1/3 ...">
                                                            <div className="loader mx-auto w-60 flex iteem-center justify-center h-60 flex iteem-center justify-center">
                                                                <LoaderIcon />
                                                            </div>
                                                            <h4 className="font-semibold text-3xl text-center">Please wait!</h4>
                                                            <p className="text-md text-gray-600 text-center">We’re verifying your KYC details.</p>
                                                        </div>
                                                        :
                                                        <div className="bg-white rounded-xl h-auto py-10 px-10 w-1/2 ...">
                                                            <div className="mx-auto mb-3 flex items-center justify-center">
                                                                <SuccessIcon />
                                                            </div>
                                                            <h4 className="font-semibold text-2xl text-center">Thank you for submitting your KYC with us!</h4>
                                                            <p className="text-md px-10 text-gray-500 text-center">Your KYC is currently under review, we’ll get back to you in the next 24hrs. Kindly explore the dashboard with our test mode feature and see how your payment transactions processes.</p>
                                                            {/* <h4 className="font-semibold text-2xl text-center">Your KYC has been verified successfully.</h4>
                                                            <p className="text-md text-gray-600 text-center">You can start accepting payment live payment from your customers by integrating with our Live APIs or using our Payment Link feature on the dashboard </p> */}
                                                            <div className="grid grid-cols-2 gap-3 px-10 mt-5">
                                                            <Link to="/support">
                                                                <button className="py-3 w-full px-8 text-primary-theme border border-primary-theme text-xs font-medium uppercase bg-red-100">VIEW DOCUMENTATION</button>
                                                            </Link>
                                                            <Link to="/">
                                                                <button className="py-3 w-full px-8 text-white bg-primary-theme border border-primary-theme text-xs font-medium uppercase bg-red-100">EXPLORE DASHBOARD</button>
                                                            </Link>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </>
                }
                {showpin ? 
            //     <Modal
            //     open={showpin} 
            //     onClose={() => setShowpin(false)}
            //     aria-labelledby="Add Branch/Category"
            //     aria-describedby="Add Branch/Category"
            //     style={{ display: 'flex', justifyContent: 'center', height: '50%', margin: 10 }}
            // >
            //   <div className="input-wrapper px-10 max-w-2xl bg-white">
            //     <h2 className="font-bold text-2xl">Add Branch/Category</h2>
            //     <Input
            //       label="branch"
            //       name="branch"
            //       placeholder="Branch/category name"
            //     //   value={branch}
            //     //   onChange={(e)=>setBranch(e.target.value)}
            //     />
            //     <Button text="Add Branch" />
            //     </div>
            // </Modal>:''
            <PinModal
            center
            showModal={showpin}
            hideModal={setShowpin}
            otp={otp}
            setOtp={setOtp}
            numInputs={6}
            handleSubmit={isEmail ? handleUpdateEmailOtp : handleUpdatePhoneOtp}
            separator=""
            buttonLabel="verify"
            title="Input your 6 digit PIN"
            description={`Please input your 6 digit OTP sent to ${isEmail ? email?.verificationReference: phone?.verificationReference} to Verify`}
          />: ''    
        }
            </div>
            
        </div>
    )
}

export default KYC
